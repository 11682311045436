import React, { Component } from "react";
import { connect } from "react-redux";

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

import {auth} from '../actions';

import { Link } from 'react-router-dom'; // Import Link
import SvgIcon from '@mui/material/SvgIcon'; // Import SvgIcon

import { ReactComponent as LogoIcon } from '../images/logoIcon.svg'; // Import your SVG

const styles = ({
  welcomebar: {
    backgroundColor: '#FFFFFF',
    zIndex: 1600
  },
  buttonDiv: {
    marginLeft: 'auto', // Adjust based on your layout needs
  },
  logoutButton: {
    textTransform: 'none',
    boxShadow: 'none',
    border: '1px solid',
    backgroundColor: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#79BDC340',

    },
    color: '#022C3F',
    borderColor: '#022C3F',
  },
});

class Header extends Component {

  logout = () => {
    this.props.logout();
  };

  render() {

    return (
      <AppBar position="sticky" style={styles.welcomebar}>
        <Toolbar>
          <Link to="/reportdashboard" style={{ textDecoration: 'none', color: 'inherit' }}>
           <SvgIcon
              component={LogoIcon}
              style={{width: '128px', height: '28px', maxHeight:'100%'}}
              viewBox="0 0 511.54 110.3"
           />
          </Link>
          <div style={styles.buttonDiv}>
            <Button
              sx={styles.logoutButton}
              type="submit"
              variant="outlined"
              onClick={this.logout}
            >
              <Typography>
                Logout
              </Typography>
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.logout()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header);
