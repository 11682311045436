import React, { Component } from "react";
import { connect } from "react-redux";
import { Navigate, Link } from "react-router-dom";
import { auth } from '../actions';

import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

const styles = ({
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  paper: {
    marginTop: "15%",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 24px 24px',
    width: '400px',
    maxWidth: '80%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  form: {
    width: '100%',
    marginTop: 36,
    textAlign: 'center'
  },
  submit: {
    marginTop: 8,
    background: '#E74C3C',
    textTransform: 'none'
  },
  submitText: {
    fontFamily: 'Comfortaa',
    fontSize: 18,
    fontWeight: 400
  },
  errorText: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    color: '#E74C3C',
  },
  loginText: {
    fontFamily: 'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  forgotPassword: {
    fontFamily: 'Comfortaa',
    fontSize: 14,
    marginTop: '10px',
    cursor: 'pointer',
    color: '#3498db'
  }
});

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginAttempted: false,
    };
  }

  onSubmit = e => {
    e.preventDefault();
    this.setState({ loginAttempted: true });
    this.props.login(this.state.username, this.state.password);
  };

  renderErrors = () => {
    if (Object.keys(this.props.errors).length > 0) {
      return (
        <div>
          Please enter a correct username and password
        </div>
      );
    }
  };

  render() {
    if (this.props.isAuthenticated === true) {
      return <Navigate to='/reportdashboard' />;
    }

    return (
      <main style={styles.main}>
        <Paper sx={styles.paper}>
          <Typography sx={styles.loginText}>neuroaide</Typography>
          {this.state.loginAttempted === true && this.renderErrors()}
          <form style={styles.form} onSubmit={this.onSubmit}>
            <label htmlFor="username"></label>
            <Input
              name="username"
              id="username"
              placeholder="username"
              fullWidth={true}
              onChange={e => this.setState({ username: e.target.value })}
            />
            <label htmlFor="password"></label>
            <Input
              name="password"
              type="password"
              id="password"
              placeholder="password"
              fullWidth={true}
              onChange={e => this.setState({ password: e.target.value })}
            />
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={styles.submit}
              disabled={this.props.isLoading}
            >
              {this.props.isLoading ? (
                <Typography sx={styles.submitText}>Signing in...</Typography>
              ) : (
                <Typography sx={styles.submitText}>Sign in</Typography>
              )}
            </Button>
          </form>
          {/* Forgot password link */}
          <Link to="/reset_password">
            <Typography sx={styles.forgotPassword}>Forgot password?</Typography>
          </Link>
        </Paper>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    errors: state.auth.errors,
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      return dispatch(auth.login(username, password));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
