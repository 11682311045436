import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import Layout from '../../shared/Layout';

import { reportGeneration, evaluation } from '../../actions';

import { logout } from '../../actions/auth';

import {
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  Snackbar,
  Alert,
  CircularProgress,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
 } from '@mui/material'

import AssignmentIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';

const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: '350px',
    overflow: 'hidden',
    marginBottom: '36px'
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },

  assessmentMenuDiv: {
    marginTop: '30px',
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    marginLeft: '5%',
    marginRight:'5%',
    marginBottom:'5px'
  },
  paper: {
    marginTop:'40px',
    marginLeft:'5%',
    marginRight:'5%',
    width: '70%',
    alignItems: 'center',
    display: 'inline-block'
  },
  submitCompletePaper: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign:'center',
    padding: '16px 24px 24px',
    width: '400px',
    marginLeft:'auto',
    marginRight:'auto'
  },
  headerText: {
    textAlign: 'left',
    width: '100%',
    marginTop: '20px',
    marginBottom: '24px',
    fontSize: '24px',
    fontWeight: 500,
    letterSpacing: '0.02em'
  },
  subHeaderText: {
    textAlign: 'left',
    width:'100%',
    fontSize: '16px',
    fontWeight: 300,
    color: '#EF4651'
  },
  infoIconText: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#3E426A'
  },
  redirectText: {
    fontSize: '18px',
    marginBottom: '30px'
  },
  redirectButton: {
    marginTop: 8,
    background:'#EF4651',
    textTransform:'none'
  },
  centeredSnackbar: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
  progressWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
  progress: {
    color: 'white',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1300,
  },
  footer: {
    backgroundColor: 'white',
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'center', // Center the buttons horizontally
  },
});

class GeneratorFormReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      evaluationNoteData: [],
      selectedSections: [],
      editRequestsBySection: {},
      selectedSection: null,
      selectAllSectionsChecked: true,
      submitted: false,
      loading: true,
      notificationOpen: false,
      notificationMessage: '',
      alertSeverity: '',
      isSubmitting: false,
      sessionExpired: false,
      openSubmitConfirmation: false,
      openEditsRequest: false,
      deidentifyChecked: false,
      outputUsageChecked: false
    };
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  componentDidMount() {
    const editRequestsBySection = {}
    const selectedSections = []
    this.props.notes.map(section => section.reportSectionTitle).forEach(section => {
      if(this.props.ableToGenerateSection(section)){
        selectedSections.push(section)
        if (this.props.regenerating){
          editRequestsBySection[section] = ''
          let sectionEditRequest = localStorage.getItem('editRequest-'+this.props.evalId.toString()+section)
          if (sectionEditRequest){
            editRequestsBySection[section] = JSON.parse(sectionEditRequest)
          }
        }
      }
    })

    this.setState({ sessionExpired: false, selectedSections: selectedSections, editRequestsBySection: editRequestsBySection, evaluationNoteData:this.props.notes, loading: false });
  }

  componentDidUpdate(prevProps) {
    if (this.props.generating && !prevProps.generating) {
      console.log("ComponentDidUpdate - generation successfully queued, setting state:", this.props.message);
      this.props.setGeneratingState(true)
      this.setState({
        notificationOpen: true,
        notificationMessage: this.props.message,
      });
    }
  }

  // Helper function to check if all eligible sections are selected after checkbox update
  areAllSectionsSelectedWithUpdatedList = (updatedSelectedSections) => {
    const eligibleSections = this.props.notes
      .map(section => section.reportSectionTitle)
      .filter(section => this.props.ableToGenerateSection(section));
    return eligibleSections.every(section => updatedSelectedSections.includes(section));
  };


  // Handle individual checkbox changes
  handleCheckboxChange = (section) => {
    const { selectedSections } = this.state;
    let updatedSelectedSections;

    if (selectedSections.includes(section)) {
      updatedSelectedSections = selectedSections.filter(s => s !== section);
    } else {
      updatedSelectedSections = [...selectedSections, section];
    }

    this.setState({
      selectedSections: updatedSelectedSections,
      selectAllSectionsChecked: this.areAllSectionsSelectedWithUpdatedList(updatedSelectedSections),
    });
  }

  handleSelectAllSectionsChange = (event) => {
    const checked = event.target.checked;
    const allSections = this.props.notes.map(section => section.reportSectionTitle);

    if (checked) {
      const enabledSections = allSections.filter(section => this.props.ableToGenerateSection(section));
      this.setState({ selectedSections: enabledSections, selectAllSectionsChecked: true });
    } else {
      this.setState({ selectedSections: [], selectAllSectionsChecked: false });
    }
  };

  handleDeidentifyChange = (event) => {
    this.setState({ deidentifyChecked: event.target.checked });
  };

  handleOutputUsageChange = (event) => {
    this.setState({ outputUsageChecked: event.target.checked });
  };

  onCloseConfirmation = () => {
    this.setState({openSubmitConfirmation:false, outputUsageChecked:false, deidentifyChecked: false})
  }

  redirectToDashboard = () => {
    this.props.resetGenerationState()
    this.props.resetEvaluationState()
    this.props.navigation('/reportdashboard');
  };

  handleClickGenerate = () => {
    this.setState({openSubmitConfirmation: true})
  };

  handleOpenEditsRequest = (section) => {
    this.setState({selectedSection: section, openEditsRequest: true})
  };

  onCloseEditsRequest = () => {
    this.setState({openEditsRequest: false})
  }

  handleEditRequestFieldChange = (section, updatedValue) => {
    // Function to handle change in dynamically generated text field
    this.setState(prevState => {
      return { editRequestsBySection: {...prevState.editRequestsBySection, [section]: updatedValue } }; // Update state
    }, this.saveEditRequestsToLocalStorage(section, updatedValue));
  };

  disableEditRequest = (section) => {
    return (
      !this.props.ableToGenerateSection(section) ||
      !this.state.selectedSections.includes(section) ||
      !this.props.notes.find(s=>s.reportSectionTitle === section) ||
      !this.props.notes.find(s=>s.reportSectionTitle === section).hasBeenGenerated
    )
  }

  saveEditRequestsToLocalStorage = (section, updatedValue) => {
    localStorage.setItem('editRequest-'+this.props.evalId.toString()+section, JSON.stringify(updatedValue));
  };

  handleSubmit = async e => {
    e.preventDefault();

    //Submit all data
    try {
       const reportData = {evalId: this.props.evalId, reportSections: this.state.selectedSections}
       if (this.props.regenerating && Object.keys(this.state.editRequestsBySection).length > 0){
         reportData.editRequests = this.state.editRequestsBySection
       }

       await this.props.generateReport(reportData);
       this.setState({ submitted: true, notificationOpen: true, notificationMessage: 'Report generation in progress!', alertSeverity: "success" }, () => {
         localStorage.removeItem('phiFields');
         localStorage.removeItem('phiFields-'+this.props.evalId.toString());
         localStorage.removeItem('currentStep');
         localStorage.removeItem('currentStep-'+this.props.evalId.toString());
         localStorage.removeItem('selectedAssessment');
         localStorage.removeItem('selectedAssessment-'+this.props.evalId.toString());
         localStorage.removeItem('openAssessment');
         localStorage.removeItem('openAssessment-'+this.props.evalId.toString());
         localStorage.removeItem('selectedAssessmentData');
         localStorage.removeItem('selectedAssessmentData-'+this.props.evalId.toString());
         localStorage.removeItem('selectedRatingScale');
         localStorage.removeItem('selectedRatingScale-'+this.props.evalId.toString());
         localStorage.removeItem('openScale');
         localStorage.removeItem('openScale-'+this.props.evalId.toString());
         localStorage.removeItem('selectedRatingScaleData');
         localStorage.removeItem('selectedRatingScaleData-'+this.props.evalId.toString());
         this.state.selectedSections.forEach(section => {
           localStorage.removeItem('editRequest-'+this.props.evalId.toString()+section)
         })
       });
     } catch (error) {
      if (error === 'Authentication error') {
        this.setState({ sessionExpired: true });
      }
      else {
        this.setState({ notificationOpen: true, notificationMessage: 'There was an error submitting your report. Please refresh the page and try again', alertSeverity:"error" });
      }
    } finally {
      this.setState({ isSubmitting: false, openSubmitConfirmation: false });
    }
  };

  redirectToLogin = () => {
    this.props.logout()
    this.props.navigation('/login')
  }

  uploadComplete = () =>
    this.state.submitted===true

  renderSubmitConfirmation = () => {
    const { openSubmitConfirmation, deidentifyChecked, outputUsageChecked } = this.state
    const canGenerate = deidentifyChecked && outputUsageChecked

    return (
      <Dialog
        open={openSubmitConfirmation}
        onClose={this.onCloseConfirmation}
        disableEscapeKeyDown={true}
        PaperProps={{
          style: {
            width: '60%', // Set the dialog to take up 50% of the screen width
            maxWidth: 'none', // Disable the default maxWidth
            marginLeft:'400px',
            overflow: 'hidden', // Ensure no overflow issues on the parent container
          },
        }}
      >
         <DialogContent
           style={{
             width: '100%',
             maxWidth: '100%', // Ensure content does not exceed the dialog width
             overflow: 'auto', // Handle any overflow within the dialog content
             padding: '20px', // Add some padding if needed
             boxSizing: 'border-box', // Include padding in the element's width and height
           }}
         >
           <Typography variant="h6" gutterBottom>
             By checking each of these boxes, I acknowledge and agree to the following:
           </Typography>
           <FormControlLabel
             control={
               <Checkbox
                 checked={deidentifyChecked}
                 onChange={this.handleDeidentifyChange}
                 color="primary"
               />
             }
             label={
               <Typography variant="body1">
                 De-Identification: I have personally reviewed the data I am submitting and ensure that it contains no Protected Health Information (PHI) or any other personally identifying information (PII). I understand that if such information remains in the data, I will indemnify and hold harmless neuroaide, Inc. (“neuroaide”) and its affiliates from any claims, losses, liabilities, or expenses arising from the presence of such information.
               </Typography>
             }
           />
           <FormControlLabel
              style={{marginTop:'15px'}}
             control={
               <Checkbox
                 checked={outputUsageChecked}
                 onChange={this.handleOutputUsageChange}
                 color="primary"
               />
             }
             label={
               <Typography variant="body1">
                 Report Output: I understand that the report generated by neuroaide is a draft report only and is intended to assist me in my professional practice. I will not distribute, share, or otherwise use the report without thorough review, editing, and professional judgment. I am solely responsible for the final report and any actions taken based on that report.
               </Typography>
             }
           />
         </DialogContent>
         <DialogActions>
           <Button onClick={this.onCloseConfirmation}>
             Cancel
           </Button>
           <Button
             onClick={this.handleSubmit}
             sx={{backgroundColor: '#EF4651'}}
             variant="contained"
             disabled={!canGenerate} // Button is disabled until both checkboxes are checked
           >
             Submit
           </Button>
         </DialogActions>
       </Dialog>
    )
  }


  renderEditsRequest = () => {
    const { openEditsRequest, selectedSection, editRequestsBySection } = this.state

    return (
      <Dialog
        open={openEditsRequest}
        disableEscapeKeyDown={true}
        PaperProps={{
          style: {
            width: '60%', // Set the dialog to take up 50% of the screen width
            maxWidth: 'none', // Disable the default maxWidth
            overflow: 'hidden', // Ensure no overflow issues on the parent container
          },
        }}
      >
         <DialogContent
           style={{
             width: '100%',
             maxWidth: '100%', // Ensure content does not exceed the dialog width
             overflow: 'auto', // Handle any overflow within the dialog content
             padding: '20px', // Add some padding if needed
             boxSizing: 'border-box', // Include padding in the element's width and height
           }}
         >
           <Typography>
             <p>Tell us the changes you would like to see reflected in the next draft of this section, relative to the previous draft. Your requests will be provided directly to neuroaide during regeneration. <p style={{textDecoration:'underline'}}>Be as specific as possible.</p></p>
           </Typography>
           <TextField
             variant="outlined"
             label={selectedSection}
             value={editRequestsBySection[selectedSection]}
             onChange={(e) => this.handleEditRequestFieldChange(selectedSection, e.target.value)}
             style={{ width: '100%', marginTop:'5px'}}
             minRows={5}
             multiline={true}
           />
         </DialogContent>
         <DialogActions>
           <Button onClick={this.onCloseEditsRequest}>
             Cancel
           </Button>
           <Button
             onClick={this.onCloseEditsRequest}
             color="primary"
             variant="contained"
             disabled={!editRequestsBySection[selectedSection]} // Button is disabled until both checkboxes are checked
           >
             Save
           </Button>
         </DialogActions>
       </Dialog>
    )
  }

  render() {
    const rootStyle = {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      textAlign: 'center',
      marginLeft: this.props.generating ? '0': '400px',
      overflow: 'hidden',
      marginBottom: '36px'
    }

    if (this.state.loading===true){
      return (
        <Layout>
          <div style={{ padding: '40px', textAlign:'left'}}>
            <Typography sx={styles.subHeaderText}>
              Loading...
            </Typography>
          </div>
        </Layout>
      )
    }

    return (
      <Layout skipLogout={this.state.sessionExpired}>
        {this.state.isSubmitting && (
          <>
            <div style={styles.overlay}></div>
            <div style={styles.progressWrapper}>
              <CircularProgress style={styles.progress} size={100} />
            </div>
          </>
        )}

        {this.renderSubmitConfirmation()}
        {this.renderEditsRequest()}
        {this.state.sessionExpired && <div style={styles.overlay}></div>}
        <div style={rootStyle}>
          {this.uploadComplete() ? (
            <div style={styles.main}>
              <Paper sx={styles.submitCompletePaper}>
                <Typography sx={styles.redirectText}>
                  Thank You!
                  <br />
                  <br />
                  This report will take approximately 10 minutes to generate.
                  <br />
                  <br />
                  Once it is complete, it will be available for download on your Report Dashboard.
                </Typography>
                <div style={styles.redirectButton}>
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    onClick={this.redirectToDashboard}
                    styles={styles.redirectButton}
                  >
                    <Typography>
                      Return to Dashboard
                    </Typography>
                  </Button>
                </div>
              </Paper>
            </div>
          ) : (
            <>
              <Typography style={styles.headerText}>
                {this.props.regenerating ? `Regenerate Report Sections`: `Generate Report`}
              </Typography>
              <div style={{width:'100%', marginBottom:'24px'}}>
                <Typography style={styles.subHeaderText}>
                {this.props.regenerating ?
                  `Select the sections you would like to be regenerated. By default, all previously selected sections will be included. Enter any specific changes you would like to see reflected in the next draft for each report section, and then click Regenerate`:
                  `Select the sections you would like to be generated, and click Generate. By default, all previously selected sections will be included.`
                }
                </Typography>
                {(this.props.notes.some(section => !this.props.ableToGenerateSection(section.reportSectionTitle)) ||
                  this.props.notes.some(section => this.disableEditRequest(section.reportSectionTitle) && this.state.selectedSections.includes(section.reportSectionTitle))) &&
                  <Box style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent:'flex-start', marginTop: '15px'}}>
                    <InfoIcon style={{marginRight:'8px', color: '#3E426A'}}/>
                    <Typography style={styles.infoIconText}>If you are unable to include a section or enter edit requests, click the corresponding info icon for instructions.</Typography>
                  </Box>
                }
              </div>
              <div style={{display: 'flex', justifyContent: 'left', width:'100%', overflowX: 'auto'}}>
                <div style={{marginBottom:'80px', minWidth:'500px'}}>
                  <TableContainer component={Paper}>
                    <Table aria-label="report section table" size="small">
                      <TableHead>
                        <TableRow style={{backgroundColor: '#F3F6F9'}}>
                          <TableCell style={{ minWidth:'250px', whiteSpace: 'nowrap' }}>
                            <Typography style={{fontWeight: 400, fontSize: '14px'}}>Report Section</Typography>
                          </TableCell>
                          <TableCell style={{ minWidth:'50px', whiteSpace: 'nowrap' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Checkbox
                                style={{ color: '#3E426A' }}
                                checked={this.state.selectAllSectionsChecked}
                                onChange={this.handleSelectAllSectionsChange}
                              />
                              <Typography style={{ fontWeight: 400, fontSize: '14px' }}>Include</Typography>
                            </div>
                          </TableCell>
                          {this.props.regenerating &&
                            <TableCell style={{  minWidth:'250px', whiteSpace: 'nowrap' }}>
                              <Typography style={{fontWeight: 400, fontSize: '14px'}}>Request Specific Edits</Typography>
                            </TableCell>
                          }
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.props.notes.map(section => section.reportSectionTitle).map((section) => (
                          <TableRow key={section}>
                            <TableCell style={{ whiteSpace: 'nowrap' }}>{section}</TableCell>
                            <TableCell>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox
                                  style={{
                                    color: this.props.ableToGenerateSection(section) ? '#3E426A' : '#bdbdbd',
                                  }}
                                  checked={this.state.selectedSections.includes(section)}
                                  onChange={() => this.handleCheckboxChange(section)}
                                  disabled={!this.props.ableToGenerateSection(section)}
                                />
                                {!this.props.ableToGenerateSection(section) && (
                                  <Tooltip title={this.props.reasonUnableToGenerateSection(section)} arrow>
                                    <InfoIcon style={{ marginLeft: '8px', cursor: 'pointer', color: '#3E426A' }} />
                                  </Tooltip>
                                )}
                              </div>
                            </TableCell>
                            {this.props.regenerating &&
                              <TableCell>
                                {section !== 'Tests Administered' &&
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                      disabled={this.disableEditRequest(section)}
                                      style={{textTransform: 'none'}}
                                      onClick={(e) => this.handleOpenEditsRequest(section)}
                                    >
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <AssignmentIcon style={{marginRight:'5px'}}/>
                                        {this.state.editRequestsBySection[section] ? `Edit your request`: `What would you like to see different?`}
                                      </div>
                                    </Button>
                                    {this.disableEditRequest(section) && this.state.selectedSections.includes(section) && (
                                      <Tooltip title={`This is your first generation of ${section}- edit requests cannot be included`} arrow>
                                        <InfoIcon style={{ marginLeft: '8px', cursor: 'pointer', color: '#3E426A' }} />
                                      </Tooltip>
                                    )}
                                    {this.state.editRequestsBySection[section] &&
                                      <TextField
                                        disabled
                                        value={this.state.editRequestsBySection[section]}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{
                                          style: {
                                            fontSize: '12px',  // Smaller font size
                                            maxWidth: '200px',  // Maximum width of the text field
                                            overflow: 'hidden',  // Ensures the text stays within bounds
                                            whiteSpace: 'nowrap',  // Prevents text from wrapping
                                            textOverflow: 'ellipsis',  // Adds the ellipsis automatically
                                          },
                                        }}
                                        style={{
                                          marginLeft: '10px',
                                          width: '200px', // Ensure the TextField container has a fixed width as well
                                        }}
                                      />
                                    }
                                  </div>
                                }
                              </TableCell>
                            }
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={styles.footer}>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      sx={{backgroundColor: '#EF4651'}}
                      onClick={this.handleClickGenerate}
                      disabled={this.state.selectedSections.length === 0}
                    >
                      <Typography>{this.props.regenerating ? `Regenerate`: `Generate`}</Typography>
                    </Button>
                  </div>
                </div>
              </div>

            </>
          )}
        </div>
        <Snackbar
          open={this.state.notificationOpen}
          autoHideDuration={8000}
          onClose={() => this.setState({ notificationOpen: false })}
        >
          <Alert onClose={() => this.setState({ notificationOpen: false })} severity={this.state.alertSeverity}>
            {this.state.notificationMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          style={styles.centeredSnackbar}
          open={this.state.sessionExpired}
          onClose={() => this.setState({ sessionExpired: false })}
        >
          <Alert
            onClose={() => this.setState({ sessionExpired: false })}
            severity="warning"
            action={
              <Button color="inherit" size="small" onClick={() => this.redirectToLogin()}>
                Login
              </Button>
            }
          >
            Your session has expired. Do not worry, all of your information is stored! Click to log back in.
          </Alert>
        </Snackbar>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    generating: state.reportGeneration.generating,
    message: state.reportGeneration.message,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    generateReport: reportData => dispatch(reportGeneration.generateReport(reportData)),
    resetGenerationState: () => dispatch(reportGeneration.resetGenerationState()),
    resetEvaluationState: () => dispatch(evaluation.resetEvaluationState()),
    logout: () => dispatch(logout()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(GeneratorFormReview));
