import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import { evaluation, subtestDirectory, compositeDirectory, ratingScaleDirectory } from '../../actions';

import { getReportSectionNameFromTitle, getTestResultSectionNames, completedSubtestInSection } from '../../shared/constants'

import Layout from '../../shared/Layout'

import Sidebar from './Sidebar';
import StartReport from './StartReport';
import AssessmentGrid from './AssessmentGrid';
import RatingScaleUploader from './RatingScaleUploader';
import EvaluationNotesUploader from './EvaluationNotesUploader';
import GeneratorFormReview from './GeneratorFormReview';

import { Button, CircularProgress, Dialog, DialogContent, DialogContentText, DialogActions, Typography } from '@mui/material';

const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
  },
  headerText: {
    textAlign: 'left',
    marginLeft: '25%',
    marginRight: '25%',
    marginTop: '5%',
    marginBottom: '24px',
    fontFamily:'Comfortaa',
    fontSize: 48,
    fontWeight: 200
  },
  overview_txt:{
    textAlign: 'left',
    marginBottom: '1%',
    marginLeft: '25%',
    marginRight: '25%',
    color: '#25404E',
    fontFamily:'Comfortaa',
  },
  logoutButton: {
    marginTop: '20px',
    marginLeft: '75%',
    background:'#E74C3C',
  },
  startButton: {
    background:'#E74C3C',
  },
  buttonText: {
    fontFamily:'Comfortaa',
      fontSize: 18,
      fontWeight: 400
  },
  startButtonDiv: {
    marginTop: '24px'
  },
  listItem: {
    marginBottom: '0px',
  },
  listItemText: {
    fontSize: 16,
  },
  layoutStyle: {
    display: 'flex',
  },
  contentStyle: {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  progressWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1500,
  }
});


class ReportGenerator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 'demographics',
      nextStepPendingConfirmation: null,
      reportData: {
        evalType: '',
        age: '',
        sex: '',
        pronouns: '',
      },
      evalId: null,
      dataUpdating: false,
      notesSaved: true,
      displayNavigationConfirmation: false,
      loaded: false,
      generating: false
    };

    this.setCurrentStep = this.setCurrentStep.bind(this);
    this.setDemographicData = this.setDemographicData.bind(this);
    this.updateAssessmentData = this.updateAssessmentData.bind(this);
    this.deleteAssessment = this.deleteAssessment.bind(this);
    this.updateRatingScaleData = this.updateRatingScaleData.bind(this);
    this.deleteRatingScale = this.deleteRatingScale.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.setNoteSaveState = this.setNoteSaveState.bind(this);
    this.setDisplayNavigationConfirmation = this.setDisplayNavigationConfirmation.bind(this);
    this.setGeneratingState = this.setGeneratingState.bind(this);
  };

  componentDidMount() {
    let evalId = null;
    let currentStep = null;

    // Extract the URL path using window.location.pathname
    const path = window.location.pathname;
    // Split the path to get the eval_id if it exists
    const parts = path.split('/');
    if (parts.length > 2){
      evalId = parts[2]; // Extract the uidb64 (e.g., 'MTA1')
    }

    if (evalId){
      const stepFromStorage = localStorage.getItem('currentStep-'+evalId.toString())
      if (stepFromStorage){
        currentStep = JSON.parse(stepFromStorage)
      }
    }

    // set current step and eval id if evailable
    this.setState({evalId: evalId, currentStep: currentStep})

    // Fetch directories
    this.props.fetchSubtestDirectory();
    this.props.fetchCompositeDirectory();
    this.props.fetchRatingScaleDirectory();

    // Fetch eval
    if (!this.props.evalLoading){
      this.props.fetchEvaluation(evalId);
      console.log('fetching eval')
    }
  }

  componentDidUpdate(prevProps) {
    const { reportData, dataUpdating, currentStep } = this.state
    const { evaluation, evalLoaded, evalAccessDenied, createdEval, assessmentDataLoaded, ratingScaleDataLoaded, notesLoaded, demographicDataLoaded, subtestDirectoryLoaded, compositeDirectoryLoaded, ratingScaleDirectoryLoaded } = this.props

    // if user doesnt have access to report, redirect to dashboard
    if (evalAccessDenied){
      this.props.navigation('/reportdashboard');
    }
    if (evalLoaded && subtestDirectoryLoaded && compositeDirectoryLoaded && ratingScaleDirectoryLoaded && !this.state.loaded){
      // if this report has been generated the max # times, redirect to dashboard
      if (evaluation.numGenerations > 2){
        return this.props.navigation('/reportdashboard');
      }

      let nextStep = evaluation.reportInProgress ? 'add-assessment-results': 'demographics'
      if (!currentStep && (evaluation.numGenerations > 0)){
        if (evaluation.numGenerations > 0){
          nextStep = 'review-and-generate'
        }
      }
      if (evaluation.reportInProgress === true) {
        this.setState({ currentStep: currentStep ? currentStep: nextStep, reportData: { ...reportData, age: evaluation.age, sex: evaluation.sex,  evalType: evaluation.evalType, pronouns: evaluation.pronouns || null } })
      }
      this.setState({loaded: true})
    }
    // after assessment data updates, refetch the eval
    if (this.state.loaded){
      if (!prevProps.createdEval && createdEval){
        this.props.navigation(`/generatereport/${evaluation.evalId}`)
        this.setState({dataUpdating: false, loaded: false })
        this.props.fetchEvaluation(evaluation.evalId)

      }
      if (!prevProps.demographicDataLoaded && demographicDataLoaded){
        this.setState({dataUpdating: false, loaded: false })
        this.props.fetchEvaluation(evaluation.evalId)
      }
      if(!prevProps.assessmentDataLoaded && assessmentDataLoaded){
        // refetch evaluation after udpating
        if (dataUpdating === true){
          this.setState({dataUpdating: false, loaded: false})
          this.props.fetchEvaluation(evaluation.evalId)
        }
      }
      if (!prevProps.ratingScaleDataLoaded && ratingScaleDataLoaded){
        // refetch evaluation after udpating
        if (dataUpdating === true){
          this.setState({dataUpdating: false, loaded: false})
          this.props.fetchEvaluation(evaluation.evalId)
        }
      }
      if (!prevProps.notesLoaded && notesLoaded){
        // refetch evaluation after udpating
        if (dataUpdating === true){
          this.setState({dataUpdating: false, loaded: false})
          this.props.fetchEvaluation(evaluation.evalId)
        }
      }
    }
  }

  saveCurrentStepToLocalStorage = () => {
    if (this.props.evaluation.evalId){
      localStorage.setItem('currentStep-'+this.props.evaluation.evalId.toString(), JSON.stringify(this.state.currentStep))
    }
  }

  setCurrentStep(step) {
    this.setState({ currentStep: step }, this.saveCurrentStepToLocalStorage);
  }

  // send to data bakcend to add or update assessments
  updateAssessmentData(data) {
      // add evalid to data before sending to server if it exists
      if (this.props.evaluation.reportInProgress){
        const evalId = this.props.evaluation.evalId
        data['evalId'] = evalId
        this.props.updateAssessmentData(data)
      }

      this.setState({
        dataUpdating: true
       }
     );
  }

  // send to data bakcend to add or update scales
  updateRatingScaleData(data) {
      // add evalid to data before sending to server if it exists
      if (this.props.evaluation.reportInProgress){
        const evalId = this.props.evaluation.evalId
        data['evalId'] = evalId
        this.props.updateRatingScaleData(data)
      }

      this.setState({
        dataUpdating: true
       }
     );
  }

  updateNotes(data) {
      // add evalid to data before sending to server if it exists
      if (this.props.evaluation.reportInProgress){
        const evalId = this.props.evaluation.evalId
        data['evalId'] = evalId
        this.props.updateNotes(data)
      }

      this.setState({
        dataUpdating: true
       }
     );
  }

  // send to bakcend to delete an assessment
  deleteAssessment(assessmentName) {
    if (this.props.evaluation.reportInProgress){
      const evalId = this.props.evaluation.evalId
      this.props.deleteAssessment(evalId, assessmentName)
    }

    this.setState({
      dataUpdating: true
     }
   );
  }

  // send to bakcend to delete an rating scale
  deleteRatingScale(ratingScaleName) {
    if (this.props.evaluation.reportInProgress){
      const evalId = this.props.evaluation.evalId
      this.props.deleteRatingScale(evalId, ratingScaleName)
    }

    this.setState({
      dataUpdating: true
     }
   );
  }

  setDemographicData(data) {
    // add evalid to data before sending to server if it exists
    if (this.props.evaluation.reportInProgress){
      const evalId = this.props.evaluation.evalId
      data['evalId'] = evalId
      this.props.updateDemographicData(data)
    } else {
      this.props.createEvaluation(data)
    }

    this.setState({
      dataUpdating: true,
      loaded: false
     }
   );
  }

  setNoteSaveState(savedState) {
    this.setState({
      notesSaved: savedState
     }
   );
  }

  setGeneratingState(generating) {
    this.setState({
      generating: generating
     }
   );
  }

  // get the reason a section cannot be generated
  reasonUnableToGenerateSection = (sectionTitle) => {
    const { subtestDirectory } = this.props
    const sectionName = getReportSectionNameFromTitle(sectionTitle)

    // Tests Administered-- just need one or more assessments or rating scales added
    if (sectionName === 'Tests Administered'){
      return 'Please add at least one assessment or rating scale in order to generate Tests Administered'
    }

    // Reason For Referral -- Background notes required
    if (sectionName === 'Reason For Referral'){
      return 'Please add Background notes in order to generate Reason For Referral'
    }

    // Background -- Background notes required
    if (sectionName === 'Background'){
      return 'Please add Background notes in order to generate'
    }

    // Behavioral Observations -- Behavioral Observations notes required
    if (sectionName === 'Behavioral Observations'){
      return 'Please add Behavioral Observations note in order to generate'
    }

    // Test result sections -- one or more subtests in that section need to be inputed
    if (getTestResultSectionNames(subtestDirectory).includes(sectionName)){
      return `Please add results for at least one ${sectionTitle} assessment in order to generate`
    }

    // Social-Emotional Functioning-- need notes or a rating scale completed
    if (sectionName === 'Social-Emotional Functioning'){
      return `In order to generate ${sectionTitle}, please add either a) results for at least one rating scale or b) ${sectionTitle} notes`
    }
    return ''
  }

  // determine if the requisite data has been entered to generate the section
  ableToGenerateSection = (sectionTitle) => {
    const { evaluation, subtestDirectory } = this.props
    const sectionName = getReportSectionNameFromTitle(sectionTitle)

    // Tests Administered-- just need one or more assessments or rating scales added
    if (sectionName === 'Tests Administered'){
      if ((evaluation.assessments.length > 0) || (evaluation.ratingScales.length > 0)){
        return true
      }
      else {
        return false
      }
    }

    // Reason For Referral -- Background notes required
    if (sectionName === 'Reason For Referral'){
      const noteSection = evaluation.sectionsAndNotes.find(s => s.reportSectionName === 'Background')
      if (noteSection){
        if (noteSection.notes){
          return true
        }
      }
      else {
        return false
      }
    }

    // Background -- Background notes required
    if (sectionName === 'Background'){
      const noteSection = evaluation.sectionsAndNotes.find(s => s.reportSectionName === 'Background')
      if (noteSection){
        if (noteSection.notes){
          return true
        }
      }
      else {
        return false
      }
    }

    // Behavioral Observations -- Behavioral Observations notes required
    if (sectionName === 'Behavioral Observations'){
      const noteSection = evaluation.sectionsAndNotes.find(s => s.reportSectionName === 'Behavioral Observations')
      if (noteSection){
        if (noteSection.notes){
          return true
        }
      }
      else {
        return false
      }
    }

    // Test result sections -- one or more subtests in that section need to be inputed
    if (getTestResultSectionNames(subtestDirectory).includes(sectionName)){
      if (completedSubtestInSection(sectionName, subtestDirectory, evaluation.assessments)){
        return true
      }
      else {
        return false
      }
    }

    // Social-Emotional Functioning-- need notes or a rating scale completed
    if (sectionName === 'Social-Emotional Functioning'){
      if (evaluation.ratingScales.length > 0) {
        return true
      }

      const noteSection = evaluation.sectionsAndNotes.find(s => s.reportSectionName === 'Social-Emotional Functioning')
      if (noteSection.notes){
        return true
      }

      return false
    }

    // Summary and Recs-- needs Background, Behavioral Observations, and test result data
    if (['Summary Of Test Results', 'Recommendations'].includes(sectionName)){
      const backgroundNoteSection = evaluation.sectionsAndNotes.find(s => s.reportSectionName === 'Background')
      const behavioralObservationsNoteSection = evaluation.sectionsAndNotes.find(s => s.reportSectionName === 'Behavioral Observations')
      if (
        (backgroundNoteSection && backgroundNoteSection.notes) &&
        (behavioralObservationsNoteSection && behavioralObservationsNoteSection.notes) &&
        (evaluation.assessments.length > 0)
      ){
        return true
      }
      else {
        return false
      }
    }
  }

  readyToGenerate = () => {
    const { evaluation } = this.props
    return (
      evaluation.sectionsAndNotes.length > 0 &&
      (
        evaluation.sectionsAndNotes.some(s => this.ableToGenerateSection(s.reportSectionTitle))
      )
      && this.state.notesSaved
    )
  }

  setDisplayNavigationConfirmation = (nextStep) => {
    this.setState({ displayNavigationConfirmation: true, nextStepPendingConfirmation: nextStep })
  }

  closeNavigationDialog = () => {
    this.setState({displayNavigationConfirmation: false})
  }

  continueNavigation = () => {
    this.setState({ displayNavigationConfirmation: false, notesSaved: true, currentStep: this.state.nextStepPendingConfirmation })
  }

  renderNavigationDialog = () => {
    const { displayNavigationConfirmation } = this.state
    return (
      <Dialog
       open={displayNavigationConfirmation}
       onClose={this.closeNavigationDialog}
       aria-labelledby="confirm-navigation-dialog"
      >
       <DialogContent>
         <DialogContentText>
          <Typography style={{color:'black', fontSize:'16px'}}>
            You have unsaved notes that will be discarded. Do you want to save them before continuing?
          </Typography>
         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button onClick={this.closeNavigationDialog}>
           Back To Notes
         </Button>
         <Button onClick={this.continueNavigation} color="error">
           Discard & Continue Without Saving
         </Button>
       </DialogActions>
      </Dialog>
    )
  }

  renderReportGenerationInProgressDialog = () => {
    const { evaluation } = this.props
    return (
      <Dialog
       open={evaluation.isGenerating}
       disableEscapeKeyDown={true}
       aria-labelledby="generation-in-progress-dialog"
       sx={{zIndex:1600}}
      >
       <DialogContent>
         <DialogContentText style={{color:'black', fontFamily:'Raleway'}}>
           Your report is currently being generated and cannot be edited until generation is complete.
         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <Button href="/reportdashboard">
           Return to Dashboard
         </Button>
       </DialogActions>
      </Dialog>
    )
  }

  renderStep() {
    const { currentStep, reportData } = this.state;
    const { subtestDirectory, compositeDirectory, ratingScaleDirectory, evaluation } = this.props

    if (evaluation.reportInProgress === true) {
      switch (currentStep) {
        case 'demographics':
          return <StartReport reportInProgress={evaluation.reportInProgress} reportData={reportData} setDemographicData={this.setDemographicData} setCurrentStep={this.setCurrentStep} />;
        case 'add-assessment-results':
          return <AssessmentGrid
                    evalId={evaluation.evalId}
                    assessmentData={evaluation.assessments}
                    updateAssessmentData={this.updateAssessmentData}
                    deleteAssessment={this.deleteAssessment}
                    subtestDirectory={subtestDirectory}
                    compositeDirectory={compositeDirectory}
                  />;
        case 'add-rating-scale-results':
          return <RatingScaleUploader
                    evalId={evaluation.evalId}
                    ratingScaleData={evaluation.ratingScales}
                    updateRatingScaleData={this.updateRatingScaleData}
                    deleteRatingScale={this.deleteRatingScale}
                    ratingScaleDirectory={ratingScaleDirectory} />;
        case 'add-evaluation-notes':
          return <EvaluationNotesUploader
                    evalId={evaluation.evalId}
                    sectionsAndNotes={evaluation.sectionsAndNotes}
                    updateNotes={this.updateNotes}
                    setNoteSaveState={this.setNoteSaveState}
                    regenerating={evaluation.hasBeenGenerated}
                  />;
        case 'review-and-generate':
          return <GeneratorFormReview
                    regenerating={evaluation.hasBeenGenerated}
                    setGeneratingState={this.setGeneratingState}
                    setCurrentStep={this.setCurrentStep}
                    evalId={evaluation.evalId}
                    ableToGenerateSection={this.ableToGenerateSection}
                    notes={evaluation.sectionsAndNotes}
                    reasonUnableToGenerateSection={this.reasonUnableToGenerateSection}
                  />;
        default:
          return <AssessmentGrid evalId={evaluation.evalId} assessmentData={evaluation.assessments} updateAssessmentData={this.updateAssessmentData} deleteAssessment={this.deleteAssessment} subtestDirectory={subtestDirectory} compositeDirectory={compositeDirectory}/>;
      }
    }
    else {
      return <StartReport reportInProgress={this.props.evaluation.reportInProgress} createEvaluation={this.props.createEvaluation} reportData={reportData} setDemographicData={this.setDemographicData} setCurrentStep={this.setCurrentStep}/>;
    }

  }

  render() {
    const { reportData, currentStep, loaded, dataUpdating, notesSaved } = this.state
    const { evalLoading } = this.props

    // Step 1: Handle the loading state
    if (evalLoading || !this.props.evaluation || !loaded || dataUpdating) {
      console.log('loading')
      return (
        <Layout>
          <div style={styles.progressWrapper}>
            <CircularProgress size={100} />
          </div>
        </Layout>
      );
    }

    return(
      <Layout>
        <div style={styles.layoutStyle}>
          {this.props.evaluation.isGenerating && <div style={styles.overlay}></div>}
          {this.renderNavigationDialog()}
          {this.renderReportGenerationInProgressDialog}
          {this.props.evaluation.reportInProgress === true && !this.state.generating &&
            <Sidebar
              setCurrentStep={this.setCurrentStep}
              currentStep={currentStep}
              reportData={reportData}
              notesSaved={notesSaved}
              setDisplayNavigationConfirmation={this.setDisplayNavigationConfirmation}
              readyToGenerate={this.readyToGenerate()}
              regenerating={this.props.evaluation.hasBeenGenerated}
            />
          }
          <div className="content" style={styles.contentStyle}>
            {this.renderStep()}
          </div>
        </div>
      </Layout>
    );
  }
};

const mapStateToProps = state => {
  return {
    auth: state.auth,
    evaluation: state.evaluation.evaluation,
    evalAccessDenied: state.evaluation.evalAccessDenied,
    evalLoading: state.evaluation.loading,
    evalLoaded: state.evaluation.loaded,
    creatingEval: state.evaluation.creating,
    createdEval: state.evaluation.created,
    assessmentDataLoaded: state.evaluation.assessmentDataLoaded,
    ratingScaleDataLoaded: state.evaluation.ratingScaleDataLoaded,
    notesLoaded: state.evaluation.notesLoaded,
    demographicDataLoaded: state.evaluation.demographicDataLoaded,
    subtestDirectory: state.subtestDirectory.subtests,
    subtestDirectoryLoaded: state.subtestDirectory.loaded,
    compositeDirectory: state.compositeDirectory.composites,
    compositeDirectoryLoaded: state.compositeDirectory.loaded,
    ratingScaleDirectory: state.ratingScaleDirectory.ratingScales,
    ratingScaleDirectoryLoaded: state.ratingScaleDirectory.loaded,
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchEvaluation: (evalId) => {
      return dispatch(evaluation.fetchEvaluation(evalId));
    },
    createEvaluation: (data) => {
      return dispatch(evaluation.createEvaluation(data));
    },
    updateAssessmentData: (data) => {
      return dispatch(evaluation.updateAssessmentData(data));
    },
    deleteAssessment: (evalId, assessmentName) => {
      return dispatch(evaluation.deleteAssessment(evalId, assessmentName));
    },
    updateRatingScaleData: (data) => {
      return dispatch(evaluation.updateRatingScaleData(data));
    },
    deleteRatingScale: (evalId, ratingScaleName) => {
      return dispatch(evaluation.deleteRatingScale(evalId, ratingScaleName));
    },
    updateNotes: (data) => {
      return dispatch(evaluation.updateNotes(data));
    },
    updateDemographicData: (data) => {
      return dispatch(evaluation.updateDemographicData(data));
    },
    fetchSubtestDirectory: () => {
      return dispatch(subtestDirectory.fetchSubtestDirectory());
    },
    fetchCompositeDirectory: () => {
      return dispatch(compositeDirectory.fetchCompositeDirectory());
    },
    fetchRatingScaleDirectory: () => {
      return dispatch(ratingScaleDirectory.fetchRatingScaleDirectory());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(ReportGenerator));
