import React, { Component } from "react";

import { List, ListItem, Tooltip, Popover, IconButton, Paper, Table, CircularProgress, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Button, Typography } from '@mui/material';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import { Navigate } from "react-router-dom";

import { appendixData, reportDownload, evaluationList, reportSection, user } from '../../actions';

import { getLocalDateStringFromUTC } from '../../shared/constants'

import Layout from '../../shared/Layout'

import { createAppendix } from './formatAppendix'

import { createReport } from './formatReport'


const styles = ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center',
    overflow: 'hidden',
    marginBottom: '36px'
  },
  paper: {
    marginTop: 32,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 24px 24px',
    width: '400px',
    marginLeft:'auto',
    marginRight:'auto'
  },
  headerDiv: {
    width:'100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginLeft: '5%',
    marginRight: '5%',
    marginTop: '40px',
    marginBottom: '24px',
  },
  headerText: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '28px',
    letterSpacing: '0.02em'
  },
  headerTableRow: {
    fontSize:'14px',
    fontWeight:400,
  },
  generateNewReportButton: {
    width: '185px',
    height: '40px',
    borderRadius: '8px 8px 8px 8px',
    padding: '8px, 16px, 8px, 16px',
    opacity: '0px',
    fontSize:'16px',
    lineHeight: '22.4px',
    fontWeight: 400,
    background: '#EF4651',
    textTransform: 'none',
    marginRight:'5%',
    marginBottom:'16px'
  },
  progressWrapper: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1400,
  },
  progress: {
    color: 'white',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 1300,
  },
  editAndGenerateButton: {
    color: '#EF4651', // Text color
    borderColor: '#EF4651', // Border color
    textTransform: 'none', // Prevents uppercase transformation
    fontSize: '14px', // Adjusts the font size
    fontWeight: 400, // Adjusts the font weight
    width:'150px',
    height:'32px',
    whiteSpace: 'nowrap', // Prevents text from wrapping
    overflow: 'hidden', // Ensures text does not overflow
    textOverflow: 'ellipsis', // Adds ellipsis if text is too long
  },
  editAndRegenerateButton: {
    color: '#625B71', // Text color
    borderColor: '#625B71', // Border color
    textTransform: 'none', // Prevents uppercase transformation
    fontSize: '14px',
    fontWeight: 400, // Adjusts the font weight
    width:'150px',
    height:'32px',
    whiteSpace: 'nowrap', // Prevents text from wrapping
    overflow: 'hidden', // Ensures text does not overflow
    textOverflow: 'ellipsis', // Adds ellipsis if text is too long
  },

});



class ReportDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      readyToDownloadRepId: null,
      readyToDownloadRepVersion: null,
      readyToDownloadAppId: null,
      dataLoading: false,
      selectedEval: {},
      anchorEl: null,
      orderDirection: 'desc',
      orderBy: 'id', // Default sorting by 'name'
    };
    this.handleDownloadAppendixClick = this.handleDownloadAppendixClick.bind(this);
    this.handleDownloadReportClick = this.handleDownloadReportClick.bind(this);
  }

  componentDidMount() {
    this.props.loadUser()
    this.props.fetchEvaluationList()
    this.props.fetchSubtestsByReportSection()
  }

  componentDidUpdate(prevProps, prevState) {
    const { appendixData, appendixLoaded, reportData, reportLoaded, user } = this.props;
    const { readyToDownloadAppId, readyToDownloadRepId, readyToDownloadRepVersion} = this.state;

    if (prevProps.user.isLoading && user.isLoaded){
      this.setState({loading: false})
    }

    if (this.state.dataLoading===true){
      if (!prevProps.reportLoaded && reportLoaded){
        createReport(readyToDownloadRepId, reportData, this.props.subtestsBySection, readyToDownloadRepVersion);
        this.setState({ readyToDownloadAppId: null, dataLoading:false }); // Reset after processing
      }
      if (!prevProps.appendixLoaded && appendixLoaded){
        createAppendix(readyToDownloadAppId, appendixData.assessments, appendixData.ratingScales, this.props.subtestsBySection);
        this.setState({ dataLoading:false })
      }
    }
  }

  handleRequestSort = (property) => {
    const isDescending = this.state.orderBy === property && this.state.orderDirection === 'desc';
    this.setState({
      orderDirection: isDescending ? 'asc' : 'desc',
      orderBy: property,
    });
  };

  compare = (a, b, orderBy) => {
    const aValue = a[orderBy];
    const bValue = b[orderBy];
    if (aValue === null || aValue === undefined) return -1; // Treat null/undefined as smaller
    if (bValue === null || bValue === undefined) return 1;

    if (['created_at', 'first_generated_at'].includes(orderBy)) {
      const dateA = new Date(aValue);
      const dateB = new Date(bValue);
      return dateA - dateB;
    } else {
      return aValue < bValue ? -1 : 1;
    }
  };

  sortRows = (rows, order, orderBy) => {
    return rows.sort((a, b) => {
      const compareResult = this.compare(a, b, orderBy);
      return order === 'asc' ? compareResult : -compareResult;
    });
  };

  handleDownloadAppendixClick = (evalId) => {
    this.props.fetchAppendixData(evalId); // Fetch the appendix data
    this.setState({ readyToDownloadAppId: evalId, dataLoading:true }); // Set the ID indicating readiness
  };

  handleDownloadReportClick = (event, evaluation) => {
    // Check if there are multiple versions
    if (evaluation.num_generations > 1) {
      this.setState({
        anchorEl: event.currentTarget,
        selectedEval: evaluation
      });
    }
    else {
      this.initiateReportDownload(evaluation, evaluation.num_generations)
    }
  };

  initiateReportDownload = (evaluation, reportVersion) => {
    this.props.fetchReportDownloadData(evaluation.id, reportVersion); // Fetch report sections
    this.setState({ readyToDownloadRepId: evaluation.id, readyToDownloadRepVersion: reportVersion, dataLoading: true }); // Set the ID indicating readiness
  }

  handleClosePopover = () => {
    this.setState({
      anchorEl: null,
      selectedEval: null
    });
  };

  handleToCreateReport = () => {
    this.props.navigation('/generatereport');
  }

  handleToEditReport = (eval_id) => {
    this.props.navigation(`/generatereport/${eval_id}`);
  }

  disableCreateNewReport = () => {
    if (!this.userLoaded()) {
      return true;
    }
    return (this.props.user.profile.num_active_reports > 9) && (!this.props.user.user.is_superuser)
  }

  userLoaded = () => {
    return (
      this.props.user &&
      this.props.user.user &&
      this.props.user.isLoaded &&
      this.props.user.profile
    )
  }

  render() {
    const { evalListLoading, evalList } = this.props
    const { anchorEl, selectedEval, orderBy, orderDirection, loading } = this.state;

    if (this.userLoaded()){
      if (this.props.user.profile.has_reset_password===false) {
        return <Navigate to='/set_password' />
      }
    }

    if ((evalListLoading!==false) || (!this.userLoaded()) || loading){
      return (
        <Layout>
          <>
            <div style={styles.overlay}></div>
            <div style={styles.progressWrapper}>
              <CircularProgress style={styles.progress} size={100} />
            </div>
          </>
        </Layout>
      )
    }

    const sortedRows = this.sortRows([...evalList], orderDirection, orderBy);

    return (
      <Layout>
        {this.state.dataLoading && (
          <>
            <div style={styles.overlay}></div>
            <div style={styles.progressWrapper}>
              <CircularProgress style={styles.progress} size={100} />
            </div>
          </>
        )}
        <div style={styles.root}>
          <div style={styles.headerDiv}>
            <Typography sx={styles.headerText}>
              Report Dashboard
            </Typography>
          </div>
          <div style={{width: '100%', textAlign:'right'}}>
            <Tooltip
              title="Please submit one of your ongoing reports before creating a new one. Up to 10 ongoing reports are allowed at once."
              disableHoverListener={!this.disableCreateNewReport()}  // Only show tooltip when the button is disabled
              arrow
            >
              <span>
                <Button
                  variant="contained"
                  sx={styles.generateNewReportButton}
                  onClick = {this.handleToCreateReport}
                  disabled = {this.disableCreateNewReport()}
                >
                  Create New Report
                </Button>
              </span>
            </Tooltip>
          </div>
          <TableContainer component={Paper} sx={{marginLeft:'5%', marginRight:'5%', maxWidth:'90%'}}>
            <Table aria-label="report table" size="small">
              <TableHead>
                <TableRow sx={{backgroundColor: '#F3F6F9', height: '43px' }}>
                  <TableCell sx={styles.headerTableRow}>
                    <TableSortLabel
                      active={orderBy === 'id'}
                      direction={orderBy === 'id' ? orderDirection : 'asc'}
                      onClick={() => this.handleRequestSort('id')}
                      hideSortIcon={false}
                    >
                      ID
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={styles.headerTableRow}>
                    <TableSortLabel
                      active={orderBy === 'created_at'}
                      direction={orderBy === 'created_at' ? orderDirection : 'asc'}
                      onClick={() => this.handleRequestSort('created_at')}
                      hideSortIcon={false}
                    >
                      Date Created
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={styles.headerTableRow}>
                    <TableSortLabel
                      active={orderBy === 'first_generated_at'}
                      direction={orderBy === 'first_generated_at' ? orderDirection : 'asc'}
                      onClick={() => this.handleRequestSort('first_generated_at')}
                      hideSortIcon={false}
                    >
                      Date Generated
                    </TableSortLabel>
                  </TableCell>
                  {(this.props.user.user.is_superuser) === true &&
                    <TableCell sx={styles.headerTableRow}>Created By</TableCell>
                  }
                  {(this.props.user.user.is_superuser) === true &&
                    <TableCell sx={styles.headerTableRow}>Organization</TableCell>
                  }
                  <TableCell sx={styles.headerTableRow}>Evaluation Type</TableCell>
                  <TableCell sx={styles.headerTableRow}>Client Sex & Age</TableCell>
                  <TableCell sx={styles.headerTableRow}># Generations</TableCell>
                  <TableCell sx={styles.headerTableRow}>Edit/Regenerate</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {evalListLoading === false && sortedRows
                .map((evaluation, index) => (
                  <TableRow key={index}>
                    <TableCell>{evaluation.id}</TableCell>
                    <TableCell>{getLocalDateStringFromUTC(evaluation.created_at)}</TableCell>
                    <TableCell>{evaluation.first_generated_at ? getLocalDateStringFromUTC(evaluation.first_generated_at): `-`}</TableCell>
                    {(this.props.user.user.is_superuser) === true &&
                      <TableCell>{evaluation.created_by}</TableCell>
                    }
                    {(this.props.user.user.is_superuser) === true &&
                      <TableCell>{evaluation.org_name}</TableCell>
                    }
                    <TableCell>{evaluation.eval_type}</TableCell>
                    <TableCell>{evaluation.sex}, {evaluation.age}</TableCell>
                    <TableCell>{evaluation.num_generations > 0 ? `${evaluation.num_generations} / 3`: `-`}</TableCell>
                    <TableCell>
                      {evaluation.is_generating ? (
                          <Button
                            variant="outlined"
                            size="small"
                            sx={{...styles.editAndGenerateButton, width:'175px'}}
                            disabled
                          >
                            Generation in progress...
                          </Button>): (
                        (evaluation.num_generations >= 3) || !evaluation.created_at ? (
                          <Button
                            variant="outlined"
                            size="small"
                            sx={styles.editAndRegenerateButton}
                            disabled
                          >
                            Edit & Regenerate
                          </Button>
                          ) : (
                          evaluation.num_generations > 0 ? (
                            <Button
                              variant="outlined"
                              size="small"
                              sx={styles.editAndRegenerateButton}
                              onClick={() => this.handleToEditReport(evaluation.id)}
                            >
                              Edit & Regenerate
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              size="small"
                              sx={styles.editAndGenerateButton}
                              onClick={() => this.handleToEditReport(evaluation.id)}
                            >
                              Edit & Generate
                            </Button>
                          )
                        ))}
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={(e) => this.handleDownloadReportClick(e, evaluation)}
                          disabled={evaluation.num_generations === 0 || evaluation.is_generating}
                        >
                          <FileDownloadIcon/>
                        </IconButton>
                        Report
                      </div>
                      <Popover
                        open={Boolean(anchorEl)}
                        anchorEl={anchorEl}
                        onClose={this.handleClosePopover}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        PaperProps={{
                          style: {
                            boxShadow: 'none', // Remove the shadow
                            border: '1px solid #ccc', // Optional: Add a border if you want
                          }
                        }}
                      >
                        <List>
                          {selectedEval && Array.from({ length: selectedEval.num_generations }).map((_, index) => (
                            <ListItem
                              button
                              style={{height:'30px'}}
                              key={index}
                              onClick={() => {
                                this.initiateReportDownload(selectedEval, index+1);
                                this.handleClosePopover();
                              }}
                            >
                              <Typography sx={{ fontSize:'14px' }}>{`Version ${index + 1}` }</Typography>
                            </ListItem>
                          ))}
                        </List>
                      </Popover>
                    </TableCell>
                    <TableCell>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <IconButton
                          onClick={() => this.handleDownloadAppendixClick(evaluation.id)}
                          disabled={evaluation.num_generations === 0 || evaluation.is_generating}
                        >
                          <FileDownloadIcon/>
                        </IconButton>
                        Appendix
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {evalList.length === 0 &&
            <div style={{width: '100%', textAlign:'left', marginLeft: '6%', marginTop:'25px'}}>
              <Typography styles={{fontSize: '14px'}}>
                No reports have been created
              </Typography>
            </div>
          }
        </div>
      </Layout>
    );
  }
}


const mapStateToProps = state => {
  return {
    evalList: state.evaluationList.evals.evalList,
    evalListLoading: state.evaluationList.loading,
    appendixData: state.appendixData.appendix,
    appendixLoaded: state.appendixData.appendixLoaded,
    subtestsBySection: state.reportSection.reportSections,
    subtestsBySectionLoading: state.reportSection.loading,
    reportData: state.reportDownload.report,
    reportLoaded: state.reportDownload.reportLoaded,
    user: state.user
  };
}

const mapDispatchToProps = dispatch => {
  return {
    fetchEvaluationList: () => dispatch(evaluationList.fetchEvaluationList()),
    fetchAppendixData: (evalId) =>  dispatch(appendixData.fetchAppendixData(evalId)),
    fetchReportDownloadData: (evalId, version) => dispatch(reportDownload.fetchReportDownloadData(evalId, version)),
    fetchSubtestsByReportSection: () => dispatch(reportSection.fetchSubtestsByReportSection()),
    loadUser: () => dispatch(user.loadUser())
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(ReportDashboard));
