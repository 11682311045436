import { jStat } from 'jstat';

export const REPORT_SECTION_TITLES = [
    "Tests Administered",
    "Reason For Referral",
    "Background",
    "Behavioral Observations",
    "General Cognitive Abilities",
    "Intellectual Functioning",
    "Language",
    "Verbal Skills",
    "Problem Solving / Executive Function Skills",
    "Attention & Executive Functioning",
    "Processing Speed",
    "Memory",
    "Verbal Memory",
    "Visual Memory",
    "Visual-Spatial & Motor Processes",
    "Visual-Motor Integration",
    "Reading",
    "Written Language",
    "Mathematics",
    "Social-Emotional Functioning",
    "Summary Of Test Results",
    "Recommendations"
]

export const REPORT_SECTION_APPENDIX_HEADER_MAPPINGS = {
    "General Cognitive Abilities": "COGNITIVE/INTELLECTUAL FUNCTIONING",
    "Intellectual Functioning": "COGNITIVE/INTELLECTUAL FUNCTIONING",
    "Performance Validity Tests": "PERFORMANCE VALIDITY TESTS",
    "Verbal Skills": "LANGUAGE",
    "Problem Solving / Executive Function Skills": "ATTENTION & EXECUTIVE FUNCTIONING",
    "Attention & Executive Functioning": "ATTENTION & EXECUTIVE FUNCTIONING",
    "Processing Speed": "PROCESSING SPEED",
    "Memory": "MEMORY",
    "Visual-Motor Integration": "VISUAL-SPATIAL & MOTOR PROCESSES",
    "Reading": "READING & READING RELATED PROCESSES",
    "Writing": "WRITTEN LANGUAGE",
    "Math": "MATH",
};

export const ALTERNATIVE_REPORT_SECTION_NAME_MAPPINGS = {
    "Intellectual Functioning": "General Cognitive Abilities",
    "Language": "Verbal Skills",
    "Attention & Executive Functioning": "Problem Solving / Executive Function Skills",
    "Visual-Spatial & Motor Processes": "Visual-Motor Integration",
    "Written Language": "Writing",
    "Mathematics": "Math"
};

export const getReportSectionNameFromTitle = (reportSectionTitle) => {
  if (Object.keys(ALTERNATIVE_REPORT_SECTION_NAME_MAPPINGS).includes(reportSectionTitle)){
    return ALTERNATIVE_REPORT_SECTION_NAME_MAPPINGS[reportSectionTitle]
  }

  return reportSectionTitle
}

// Return list of distinct test result section names from subtestdirectory
export const getTestResultSectionNames = (subtestDirectory) => {
  const distinctReportSectionNames = new Set();
  distinctReportSectionNames.add('Memory')
  distinctReportSectionNames.add('General Cognitive Abilities')
  Object.keys(subtestDirectory).forEach(assmnt => {
    if (subtestDirectory[assmnt].subtests) {
      subtestDirectory[assmnt].subtests.forEach(subtest => {
        if (subtest.reportSectionName) {
          distinctReportSectionNames.add(subtest.reportSectionName);
        }
      });
    }
  })
  return [...distinctReportSectionNames]
}

// Return true if any subtest in the section has been completed, otherwise false
export const completedSubtestInSection = (sectionName, subtestDirectory, assessmentData) => {
  const completedTestInSection = assessmentData.some(assessment => {
    const assessmentName = assessment.name
    const assmntDirEntry = subtestDirectory[assessmentName]
    if (sectionName === 'General Cognitive Abilities'){
      if (assmntDirEntry.is_cognitive_ability_test){
        return true
      }
    }
    return assessment.subtests.some(subtest => {
      const subtestDirEntry = assmntDirEntry.subtests.find(s => s.subtest_name === subtest.name)
      if (subtestDirEntry){
        if (sectionName === 'Memory'){
          if (['Visual Memory', 'Verbal Memory'].includes(subtestDirEntry.reportSectionName)){
            return true
          }
        }
        else if (subtestDirEntry.reportSectionName === sectionName){
          return true
        }
      }
      return false
    })
  })
  return completedTestInSection
}

export const ORDERED_COGNITIVE_ABILITY_TEST_COMPOSITES = {
  "Wechsler Intelligence Scale for Children - Fifth Edition":
        [
            "Full Scale IQ",
            "General Ability Index",
            "Verbal Comprehension Index",
            "Visual Spatial Index",
            "Fluid Reasoning Index",
            "Working Memory Index",
            "Processing Speed Index",

        ],
    "Wechsler Adult Intelligence Scale - Fourth Edition":
        [
            "Full Scale IQ",
            "General Ability Index",
            "Verbal Comprehension Index",
            "Perceptual Reasoning Index",
            "Working Memory Index",
            "Processing Speed Index",

        ],
    "Wechsler Preschool & Primary Scale of Intelligence - Fourth Edition":
        [
          "Full Scale IQ",
          "General Ability Index",
          "Cognitive Proficiency Index",
          "Fluid Reasoning Index",
          "Processing Speed Index",
          "Verbal Comprehension Index",
          "Visual Spatial Index",
          "Working Memory Index"
        ]
}

export const EVALUATION_NOTE_SECTIONS = [
  "Background",
  "Behavioral Observations",
  "General Cognitive Abilities",
  "Intellectual Functioning",
  "Language",
  "Verbal Skills",
  "Problem Solving / Executive Function Skills",
  "Attention & Executive Functioning",
  "Processing Speed",
  "Memory",
  "Verbal Memory",
  "Visual Memory",
  "Visual-Spatial & Motor Processes",
  "Visual-Motor Integration",
  "Reading",
  "Written Language",
  "Mathematics",
  "Social-Emotional Functioning"
];

export const CONFLICTING_REPORT_SECTIONS = {
  "General Cognitive Abilities": ["Intellectual Functioning"],
  "Intellectual Functioning": ["General Cognitive Abilities"],
  "Verbal Skills": ["Language"],
  "Language": ["Verbal Skills"],
  "Problem Solving / Executive Function Skills": ["Attention & Executive Functioning"],
  "Attention & Executive Functioning": ["Problem Solving / Executive Function Skills"],
  "Verbal Memory": ["Memory"],
  "Visual Memory": ["Memory"],
  "Memory": ["Verbal Memory", "Visual Memory"],
  "Visual-Motor Integration": ["Visual-Spatial & Motor Processes"],
  "Visual-Spatial & Motor Processes": ["Visual-Motor Integration"],
};

export const REQUIRED_REPORT_NOTE_SECTIONS = [
  "Background",
  "Behavioral Observations"
];

// Rating Scales
export const RATING_SCALE_FORM_TYPES = [
  'Self',
  'Parent',
  'Teacher'
];

export const RATER_FORM_TYPE_MAPPINGS = {
  'Self': 'Self',
  'Parent 1': 'Parent',
  'Parent 2': 'Parent',
  'Teacher 1': 'Teacher',
  'Teacher 2': 'Teacher'
}

export const RATER_TYPES = [
  'Self',
  'Parent 1',
  'Parent 2',
  'Teacher 1',
  'Teacher 2'
]

// Deidentifier
export const REQUIRED_PHI_FIELDS = ["Client First Name", "Client Last Name"]
export const DATE_PHI_FIELDS = ["Client Date of Birth"]

export const PHI_FIELDS = {
  'Client Personal Information':
    [
      "Client First Name",
      "Client Last Name",
      "Client Date of Birth",
      "Client City of Residence",
      "Client Street Address",
      "Client Zip Code",
      "Client is referred to as (nickname)",
    ],
  'Family Information':
    [
      "Client Mother First Name",
      "Client Mother Last Name",
      "Client Father First Name",
      "Client Father Last Name",
    ],

  'Schools Attended':
    [
      "Current School",
      "First School",
      "Second School",
      "Third School",
      "Fourth School",
      "Fifth School"
    ],
  'Teachers Referenced':
    [
      "Client Teacher First Name",
      "Client Teacher Last Name",
    ],
  'Support Practitioners (Dr.s, psychologists, tutors, etc.)':
  [
    "Client Practitioner First Name",
    "Client Practitioner Last Name",
  ]
}

export const DEIDENTIFICATION_FIELD_MAPPINGS_ORDERED = {
  'Client Personal Information': {
    "Client Full Name": '[Client]',
    "Client First Name": '[Client]',
    "Client Last Name": '[Client Last Name]',
    "Client Date of Birth": '[Client Date of Birth]',
    "Client is referred to as (nickname)": '[Client]',
    "Client City of Residence": "[Client City of Residence]",
    "Client Street Address": "[Client Address]",
    "Client Zip Code": "[Client Zip Code]",
  },
  'Family Information': {
    "Client Mother Full Name": '[Client Mother]',
    "Client Mother First Name": '[Client Mother]',
    "Client Mother Last Name": '[Client Last Name]',
    "Client Father Full Name": '[Client Father]',
    "Client Father First Name": '[Client Father]',
    "Client Father Last Name": '[Client Last Name]',
  },
  'Schools Attended': {
    "Current School": '[Client Current School]',
    "First School": '[Client First School]',
    "Second School": '[Client Second School]',
    "Third School": '[Client Third School]',
    "Fourth School": '[Client Fourth School]',
    "Fifth School": '[Client Fifth School]',
  },
  'Teachers Referenced': {
    "Client Teacher Full Name": "[Client Teacher]",
    "Client Teacher First Name": "[Client Teacher]",
    "Client Teacher Last Name": "[Client Teacher]",
  },
  'Support Practitioners (Dr.s, psychologists, tutors, etc.)': {
    "Client Practitioner Full Name": "[Client Practitioner]",
    "Client Practitioner First Name": "[Client Practitioner]",
    "Client Practitioner Last Name": "[Client Practitioner]"
  }
}

export const numberToOrdinal = number => {
  const specialCases = ["th", "st", "nd", "rd"],
        ordinalMap = [
          "Zeroth", "First", "Second", "Third", "Fourth", "Fifth", "Sixth", "Seventh", "Eighth", "Ninth",
          "Tenth", "Eleventh", "Twelfth", "Thirteenth", "Fourteenth", "Fifteenth", "Sixteenth",
          "Seventeenth", "Eighteenth", "Nineteenth"
        ];
  let suffix = specialCases[0]; // Default suffix is 'th'

  if (number < 20) {
    return ordinalMap[number];
  } else {
    let lastDigit = number % 10;
    if (lastDigit < 4 && Math.floor((number % 100) / 10) !== 1) {
      suffix = specialCases[lastDigit];
    }
    // Capitalize the first letter of the ordinal suffix and concatenate
    let ordinal = number + suffix;
    return ordinal.charAt(0).toUpperCase() + ordinal.slice(1);
  }
}

export const SCORE_DISTRIBUTION_PARAMETERS = {
  'scaled_score': {mean: 10, sd: 3},
  'standard_score': {mean: 100, sd: 15},
  't_score': {mean: 50, sd: 10},
  'z_score': {mean: 0, sd: 1},
  'v_scale': {mean: 15, sd: 3}
};

export const calulatePercentileFromScore = (mean, stdDev, score ) => {
  const zScore = (score - mean) / stdDev;
  const percentile = jStat.normal.cdf(zScore, 0, 1) * 100;
  return percentile.toFixed(2);
};

export const roundPercentile = (value) => {
  // Round to two decimal places
  let roundedValue = Math.round(value * 100) / 100;

  // Limit the value to the range 0.01 - 99.99
  if (roundedValue < 0.01) {
    roundedValue = 0.01;
  } else if (roundedValue > 99.99) {
    roundedValue = 99.99;
  }
  else if (!((roundedValue < 1) || (roundedValue > 99))){
    roundedValue = Math.round(value)
  }

  return roundedValue;
}


export const DEMO_DATA_ASSESSMENTS = [
    {
        "name": "Wechsler Intelligence Scale for Children - Fifth Edition",
        "subtests": [
            {
                "name": "Similarities",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            },
            {
                "name": "Vocabulary",
                "percentile": 75,
                "tScore": 12,
                "outcome": null
            },
            {
                "name": "Information",
                "percentile": 50,
                "tScore": 10,
                "outcome": null
            },
            {
                "name": "Comprehension",
                "percentile": 50,
                "tScore": 10,
                "outcome": null
            },
            {
                "name": "Block Design",
                "percentile": 91,
                "tScore": 14,
                "outcome": null
            },
            {
                "name": "Visual Puzzles",
                "percentile": 91,
                "tScore": 14,
                "outcome": null
            },
            {
                "name": "Matrix Reasoning",
                "percentile": 95,
                "tScore": 15,
                "outcome": null
            },
            {
                "name": "Figure Weights",
                "percentile": 91,
                "tScore": 14,
                "outcome": null
            },
            {
                "name": "Coding",
                "percentile": 37,
                "tScore": 9,
                "outcome": null
            },
            {
                "name": "Symbol Search",
                "percentile": 50,
                "tScore": 10,
                "outcome": null
            },
            {
                "name": "Cancellation",
                "percentile": 50,
                "tScore": 10,
                "outcome": null
            },
            {
                "name": "Digit Span",
                "percentile": 37,
                "tScore": 9,
                "outcome": null
            },
            {
                "name": "Letter-Number Sequencing",
                "percentile": 50,
                "tScore": 10,
                "outcome": null
            },
            {
                "name": "Picture Span",
                "percentile": 75,
                "tScore": 12,
                "outcome": null
            }
        ],
        "composites": [
            {
                "name": "Verbal Comprehension Index",
                "percentile": 70,
                "tScore": 108
            },
            {
                "name": "Visual Spatial Index",
                "percentile": 93,
                "tScore": 122
            },
            {
                "name": "Fluid Reasoning Index",
                "percentile": 96,
                "tScore": 126
            },
            {
                "name": "Working Memory Index",
                "percentile": 58,
                "tScore": 103
            },
            {
                "name": "Processing Speed Index",
                "percentile": 45,
                "tScore": 98
            },
            {
                "name": "Full Scale IQ",
                "percentile": 84,
                "tScore": 115
            }
        ]
    },
    {
        "name": "Boston Naming Test",
        "subtests": [
            {
                "name": "Boston Naming Test",
                "percentile": null,
                "tScore": null,
                "outcome": "52/60 identified"
            }
        ]
    },
    {
        "name": "Verbal Fluency Test",
        "subtests": [
            {
                "name": "Verbal Fluency Test",
                "percentile": null,
                "tScore": null,
                "outcome": "generated 28 words mean is 42 +-6"
            }
        ]
    },
    {
        "name": "Rey Osterrieth Complex Figure Drawing",
        "subtests": [
            {
                "name": "Immediate Recall",
                "percentile": 88,
                "tScore": 62,
                "outcome": null
            },
            {
                "name": "Delayed Recall",
                "percentile": 90,
                "tScore": 63,
                "outcome": null
            },
            {
                "name": "Recognition",
                "percentile": 54,
                "tScore": 51,
                "outcome": null
            }
        ]
    },
    {
        "name": "Trail Making Test",
        "subtests": [
            {
                "name": "Trail Making Test A",
                "has_measures": true,
                "measures": [
                  {"measureName": "Trails A Time", "percentile":25, "tScore":null, "outcome":null}
                ]
            },
            {
                "name": "Trail Making Test B",
                "has_measures": true,
                "measures": [
                  {"measureName": "Trails B Time", "percentile":null, "tScore":null, "outcome":">10 Percent"}
                ]
            }
        ]
    },
    {
        "name": "Wide Range Assessment of Memory and Learning - Third Edition",
        "subtests": [
            {
                "name": "Sentence Memory",
                "percentile": 25,
                "tScore": 8,
                "outcome": null
            },
            {
                "name": "Story Memory",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            },
            {
                "name": "Story Memory Delayed",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            },
            {
                "name": "Story Memory Recognition",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            },
            {
                "name": "Picture Memory",
                "percentile": 37,
                "tScore": 9,
                "outcome": null
            },
            {
                "name": "Picture Memory Delayed",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            },
            {
                "name": "Picture Memory Recognition",
                "percentile": 75,
                "tScore": 12,
                "outcome": null
            }
        ]
    },
    {
        "name": "Children’s Auditory Verbal Learning Test - Second Edition",
        "subtests": [
            {
                "name": "Immediate Memory Span",
                "percentile": 9,
                "tScore": 80,
                "outcome": null
            },
            {
                "name": "Level of Learning",
                "percentile": 18,
                "tScore": 86,
                "outcome": null
            },
            {
                "name": "Interference Trial",
                "percentile": 63,
                "tScore": 105,
                "outcome": null
            },
            {
                "name": "Immediate Recall",
                "percentile": 30,
                "tScore": 92,
                "outcome": null
            },
            {
                "name": "Delayed Recall",
                "percentile": 5,
                "tScore": 75,
                "outcome": null
            },
            {
                "name": "Recognition Trial",
                "percentile": null,
                "tScore": null,
                "outcome": "28/32 i"
            }
        ]
    },
    {
        "name": "Test of Word Reading Efficiency - Second Edition",
        "subtests": [
            {
                "name": "Sight Word Efficiency",
                "percentile": 47,
                "tScore": 99,
                "outcome": null
            }
        ]
    },
    {
        "name": "Woodcock-Johnson Test of Achievement - Fourth Edition",
        "subtests": [
            {
                "name": "Letter-Word Identification",
                "percentile": 83,
                "tScore": 114,
                "outcome": null
            },
            {
                "name": "Reading Recall",
                "percentile": 74,
                "tScore": 110,
                "outcome": null
            },
            {
                "name": "Sentence Reading Fluency",
                "percentile": 54,
                "tScore": 102,
                "outcome": null
            },
            {
                "name": "Word Reading Fluency",
                "percentile": 42,
                "tScore": 97,
                "outcome": null
            },
            {
                "name": "Spelling",
                "percentile": 45,
                "tScore": 98,
                "outcome": null
            },
            {
                "name": "Writing Samples",
                "percentile": 84,
                "tScore": 115,
                "outcome": null
            },
            {
                "name": "Applied Problems",
                "percentile": 96,
                "tScore": 126,
                "outcome": null
            },
            {
                "name": "Math Facts Fluency",
                "percentile": 46,
                "tScore": 98,
                "outcome": null
            }
        ]
    },
    {
        "name": "Gray Oral Reading Test - Fifth Edition - Form A",
        "subtests": [
            {
                "name": "Rate",
                "percentile": 50,
                "tScore": 10,
                "outcome": null
            },
            {
                "name": "Accuracy",
                "percentile": 84,
                "tScore": 13,
                "outcome": null
            },
            {
                "name": "Fluency",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            },
            {
                "name": "Comprehension",
                "percentile": 75,
                "tScore": 12,
                "outcome": null
            }
        ]
    },
    {
        "name": "Test of Written Language - Fourth Edition - Form A",
        "subtests": [
            {
                "name": "Spontaneous Writing",
                "percentile": 68,
                "tScore": 107,
                "outcome": null
            },
            {
                "name": "Contextual Conventions",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            },
            {
                "name": "Story Composition",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            }
        ]
    },
    {
        "name": "Nelson Denny Reading Test",
        "subtests": [
            {
                "name": "Reading Rate",
                "percentile": 14,
                "tScore": 85,
                "outcome": null
            },
            {
                "name": "Comprehension",
                "percentile": 82,
                "tScore": 114,
                "outcome": null
            },
            {
                "name": "Extended Time Comprehension",
                "percentile": 98,
                "tScore": 130,
                "outcome": null
            }
        ]
    },
    {
        "name": "Wisconsin Card Sorting Test",
        "subtests": [
            {
                "name": "Wisconsin Card Sorting Test",
                "has_measures": true,
                "measures": [
                  {"measureName": "Categories Completed", "percentile":null, "tScore":null, "outcome":"7 Categories Completed"},
                  {"measureName": "Perseverative Errors", "percentile":null, "tScore":null, "outcome":"2 Persverative errors 2"},
                  {"measureName": "Nonperseverative Errors", "percentile":null, "tScore":null, "outcome":"14 Non-Perseverative Errors"}
                ],
                "percentile": null,
                "tScore": null,
                "outcome": null
            }
        ]
    },
    {
        "name": "Rapid Automatized Naming Test",
        "subtests": [
            {
                "name": "Objects",
                "percentile": 37,
                "tScore": 95,
                "outcome": null
            },
            {
                "name": "Colors",
                "percentile": 45,
                "tScore": 98,
                "outcome": null
            },
            {
                "name": "Numbers",
                "percentile": 42,
                "tScore": 97,
                "outcome": null
            },
            {
                "name": "Letters",
                "percentile": 47,
                "tScore": 99,
                "outcome": null
            },
            {
                "name": "2-Set Letters and Numbers",
                "percentile": 58,
                "tScore": 105,
                "outcome": null
            },
            {
                "name": "3-Set Letters, Numbers & Colors",
                "percentile": 58,
                "tScore": 103,
                "outcome": null
            }
        ]
    },
    {
        "name": "CELF, Metalinguistics - Fifth Edition",
        "subtests": [
            {
                "name": "Making Inferences",
                "percentile": 37,
                "tScore": 9,
                "outcome": null
            },
            {
                "name": "Conversational Skills",
                "percentile": 63,
                "tScore": 11,
                "outcome": null
            }
        ]
    }
]

export const DEMO_DATA_RATING_SCALES = [
    {
        "name": "Behavior Rating Inventory of Executive Function – Second Edition",
        "forms": [
            {
                "rater": "Parent 1",
                "form_type": "Parent",
                "measures": [
                    {
                        "name": "Inhibit",
                        "tScore": 40,
                        "percentile": null
                    },
                    {
                        "name": "Self-Monitor",
                        "tScore": 39,
                        "percentile": null
                    },
                    {
                        "name": "Behavior Regulation Index (BRI)",
                        "tScore": 39,
                        "percentile": null
                    },
                    {
                        "name": "Shift",
                        "tScore": 41,
                        "percentile": null
                    },
                    {
                        "name": "Emotional Control",
                        "tScore": 41,
                        "percentile": null
                    },
                    {
                        "name": "Initiate",
                        "tScore": 40,
                        "percentile": null
                    },
                    {
                        "name": "Task-Monitor",
                        "tScore": 52,
                        "percentile": null
                    },
                    {
                        "name": "Organization of Materials",
                        "tScore": 49,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            },
            {
                "rater": "Self",
                "form_type": "Self",
                "measures": [
                    {
                        "name": "Inhibit",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Self-Monitor",
                        "tScore": 42,
                        "percentile": null
                    },
                    {
                        "name": "Behavior Regulation Index (BRI)",
                        "tScore": 44,
                        "percentile": null
                    },
                    {
                        "name": "Shift",
                        "tScore": 52,
                        "percentile": null
                    },
                    {
                        "name": "Emotional Control",
                        "tScore": 41,
                        "percentile": null
                    },
                    {
                        "name": "Task-Completion",
                        "tScore": 71,
                        "percentile": null
                    },
                    {
                        "name": "Working Memory",
                        "tScore": 56,
                        "percentile": null
                    },
                    {
                        "name": "Plan/Organize",
                        "tScore": 64,
                        "percentile": null
                    },
                    {
                        "name": "Cognitive Regulation Index (CRI)",
                        "tScore": 65,
                        "percentile": null
                    },
                    {
                        "name": "Global Executive Composite (GEC)",
                        "tScore": 56,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            },
            {
                "rater": "Teacher 1",
                "form_type": "Teacher",
                "measures": [
                    {
                        "name": "Inhibit",
                        "tScore": 52,
                        "percentile": null
                    },
                    {
                        "name": "Self-Monitor",
                        "tScore": 47,
                        "percentile": null
                    },
                    {
                        "name": "Behavior Regulation Index (BRI)",
                        "tScore": 50,
                        "percentile": null
                    },
                    {
                        "name": "Shift",
                        "tScore": 58,
                        "percentile": null
                    },
                    {
                        "name": "Emotional Control",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Initiate",
                        "tScore": 62,
                        "percentile": null
                    },
                    {
                        "name": "Working Memory",
                        "tScore": 71,
                        "percentile": null
                    },
                    {
                        "name": "Plan/Organize",
                        "tScore": 71,
                        "percentile": null
                    },
                    {
                        "name": "Task-Monitor",
                        "tScore": 67,
                        "percentile": null
                    },
                    {
                        "name": "Organization of Materials",
                        "tScore": 68,
                        "percentile": null
                    },
                    {
                        "name": "Cognitive Regulation Index (CRI)",
                        "tScore": 69,
                        "percentile": null
                    },
                    {
                        "name": "Global Executive Composite (GEC)",
                        "tScore": 62,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            },
            {
                "rater": "Teacher 2",
                "form_type": "Teacher",
                "measures": [
                    {
                        "name": "Inhibit",
                        "tScore": 47,
                        "percentile": null
                    },
                    {
                        "name": "Self-Monitor",
                        "tScore": 47,
                        "percentile": null
                    },
                    {
                        "name": "Behavior Regulation Index (BRI)",
                        "tScore": 47,
                        "percentile": null
                    },
                    {
                        "name": "Shift",
                        "tScore": 55,
                        "percentile": null
                    },
                    {
                        "name": "Emotional Control",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Initiate",
                        "tScore": 72,
                        "percentile": null
                    },
                    {
                        "name": "Working Memory",
                        "tScore": 80,
                        "percentile": null
                    },
                    {
                        "name": "Plan/Organize",
                        "tScore": 65,
                        "percentile": null
                    },
                    {
                        "name": "Task-Monitor",
                        "tScore": 73,
                        "percentile": null
                    },
                    {
                        "name": "Organization of Materials",
                        "tScore": 63,
                        "percentile": null
                    },
                    {
                        "name": "Cognitive Regulation Index (CRI)",
                        "tScore": 72,
                        "percentile": null
                    },
                    {
                        "name": "Global Executive Composite (GEC)",
                        "tScore": 62,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            }
        ]
    },
    {
        "name": "The Behavior Assessment System for Children - Third Edition",
        "forms": [
            {
                "rater": "Parent 1",
                "form_type": "Parent",
                "measures": [
                    {
                        "name": "Hyperactivity",
                        "tScore": 39,
                        "percentile": null
                    },
                    {
                        "name": "Aggression",
                        "tScore": 45,
                        "percentile": null
                    },
                    {
                        "name": "Conduct Problems",
                        "tScore": 41,
                        "percentile": null
                    },
                    {
                        "name": "Externalizing Problems",
                        "tScore": 41,
                        "percentile": null
                    },
                    {
                        "name": "Anxiety",
                        "tScore": 41,
                        "percentile": null
                    },
                    {
                        "name": "Depression",
                        "tScore": 40,
                        "percentile": null
                    },
                    {
                        "name": "Somatization",
                        "tScore": 39,
                        "percentile": null
                    },
                    {
                        "name": "Internalizing Problems",
                        "tScore": 39,
                        "percentile": null
                    },
                    {
                        "name": "Atypicality",
                        "tScore": 42,
                        "percentile": null
                    },
                    {
                        "name": "Withdrawal",
                        "tScore": 38,
                        "percentile": null
                    },
                    {
                        "name": "Attention Problems",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Behavioral Symptoms Index",
                        "tScore": 40,
                        "percentile": null
                    },
                    {
                        "name": "Adaptability",
                        "tScore": 62,
                        "percentile": null
                    },
                    {
                        "name": "Social Skills",
                        "tScore": 62,
                        "percentile": null
                    },
                    {
                        "name": "Leadership",
                        "tScore": 57,
                        "percentile": null
                    },
                    {
                        "name": "Functional Communication",
                        "tScore": 48,
                        "percentile": null
                    },
                    {
                        "name": "Activities of Daily Living",
                        "tScore": 51,
                        "percentile": null
                    },
                    {
                        "name": "Adaptive Skills",
                        "tScore": 57,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            },
            {
                "rater": "Client",
                "form_type": "Self",
                "measures": [
                    {
                        "name": "Attitude to School",
                        "tScore": 38,
                        "percentile": null
                    },
                    {
                        "name": "Attitude to Teachers",
                        "tScore": 41,
                        "percentile": null
                    },
                    {
                        "name": "Sensation Seeking",
                        "tScore": 43,
                        "percentile": null
                    },
                    {
                        "name": "School Problems",
                        "tScore": 37,
                        "percentile": null
                    },
                    {
                        "name": "Atypicality",
                        "tScore": 41,
                        "percentile": null
                    },
                    {
                        "name": "Locus of Control",
                        "tScore": 49,
                        "percentile": null
                    },
                    {
                        "name": "Social Stress",
                        "tScore": 39,
                        "percentile": null
                    },
                    {
                        "name": "Anxiety",
                        "tScore": 51,
                        "percentile": null
                    },
                    {
                        "name": "Depression",
                        "tScore": 42,
                        "percentile": null
                    },
                    {
                        "name": "Sense of Inadequacy",
                        "tScore": 57,
                        "percentile": null
                    },
                    {
                        "name": "Somatization",
                        "tScore": 42,
                        "percentile": null
                    },
                    {
                        "name": "Internalizing Problems",
                        "tScore": 45,
                        "percentile": null
                    },
                    {
                        "name": "Attention Problems",
                        "tScore": 53,
                        "percentile": null
                    },
                    {
                        "name": "Hyperactivity",
                        "tScore": 38,
                        "percentile": null
                    },
                    {
                        "name": "Inattention/Hyperactivity",
                        "tScore": 45,
                        "percentile": null
                    },
                    {
                        "name": "Relations with Parents",
                        "tScore": 61,
                        "percentile": null
                    },
                    {
                        "name": "Interpersonal Relations",
                        "tScore": 58,
                        "percentile": null
                    },
                    {
                        "name": "Self-Esteem",
                        "tScore": 53,
                        "percentile": null
                    },
                    {
                        "name": "Self-Reliance",
                        "tScore": 55,
                        "percentile": null
                    },
                    {
                        "name": "Personal Adjustment",
                        "tScore": 59,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            },
            {
                "rater": "Teacher 1",
                "form_type": "Teacher",
                "measures": [
                    {
                        "name": "Hyperactivity",
                        "tScore": 45,
                        "percentile": null
                    },
                    {
                        "name": "Aggression",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Conduct Problems",
                        "tScore": 57,
                        "percentile": null
                    },
                    {
                        "name": "Externalizing Problems",
                        "tScore": 49,
                        "percentile": null
                    },
                    {
                        "name": "Anxiety",
                        "tScore": 58,
                        "percentile": null
                    },
                    {
                        "name": "Depression",
                        "tScore": 45,
                        "percentile": null
                    },
                    {
                        "name": "Somatization",
                        "tScore": 49,
                        "percentile": null
                    },
                    {
                        "name": "Internalizing Problems",
                        "tScore": 51,
                        "percentile": null
                    },
                    {
                        "name": "Attention Problems",
                        "tScore": 59,
                        "percentile": null
                    },
                    {
                        "name": "Learning Problems",
                        "tScore": 62,
                        "percentile": null
                    },
                    {
                        "name": "School Problems",
                        "tScore": 61,
                        "percentile": null
                    },
                    {
                        "name": "Atypicality",
                        "tScore": 50,
                        "percentile": null
                    },
                    {
                        "name": "Withdrawal",
                        "tScore": 58,
                        "percentile": null
                    },
                    {
                        "name": "Behavioral Symptoms Index",
                        "tScore": 51,
                        "percentile": null
                    },
                    {
                        "name": "Adaptability",
                        "tScore": 40,
                        "percentile": null
                    },
                    {
                        "name": "Social Skills",
                        "tScore": 44,
                        "percentile": null
                    },
                    {
                        "name": "Leadership",
                        "tScore": 40,
                        "percentile": null
                    },
                    {
                        "name": "Study Skills",
                        "tScore": 44,
                        "percentile": null
                    },
                    {
                        "name": "Functional Communication",
                        "tScore": 44,
                        "percentile": null
                    },
                    {
                        "name": "Adaptive Skills",
                        "tScore": 42,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            },
            {
                "rater": "Teacher 2",
                "form_type": "Teacher",
                "measures": [
                    {
                        "name": "Atypicality",
                        "tScore": 56,
                        "percentile": null
                    },
                    {
                        "name": "Hyperactivity",
                        "tScore": 44,
                        "percentile": null
                    },
                    {
                        "name": "Aggression",
                        "tScore": 43,
                        "percentile": null
                    },
                    {
                        "name": "Conduct Problems",
                        "tScore": 43,
                        "percentile": null
                    },
                    {
                        "name": "Externalizing Problems",
                        "tScore": 43,
                        "percentile": null
                    },
                    {
                        "name": "Anxiety",
                        "tScore": 45,
                        "percentile": null
                    },
                    {
                        "name": "Depression",
                        "tScore": 45,
                        "percentile": null
                    },
                    {
                        "name": "Somatization",
                        "tScore": 49,
                        "percentile": null
                    },
                    {
                        "name": "Internalizing Problems",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Attention Problems",
                        "tScore": 65,
                        "percentile": null
                    },
                    {
                        "name": "Learning Problems",
                        "tScore": 64,
                        "percentile": null
                    },
                    {
                        "name": "School Problems",
                        "tScore": 65,
                        "percentile": null
                    },
                    {
                        "name": "Withdrawal",
                        "tScore": 51,
                        "percentile": null
                    },
                    {
                        "name": "Behavioral Symptoms Index",
                        "tScore": 51,
                        "percentile": null
                    },
                    {
                        "name": "Adaptability",
                        "tScore": 36,
                        "percentile": null
                    },
                    {
                        "name": "Social Skills",
                        "tScore": 27,
                        "percentile": null
                    },
                    {
                        "name": "Leadership",
                        "tScore": 31,
                        "percentile": null
                    },
                    {
                        "name": "Study Skills",
                        "tScore": 34,
                        "percentile": null
                    },
                    {
                        "name": "Functional Communication",
                        "tScore": 33,
                        "percentile": null
                    },
                    {
                        "name": "Adaptive Skills",
                        "tScore": 30,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            },
            {
                "rater": "Teacher 3",
                "form_type": "Teacher",
                "measures": [
                    {
                        "name": "Hyperactivity",
                        "tScore": 42,
                        "percentile": null
                    },
                    {
                        "name": "Aggression",
                        "tScore": 51,
                        "percentile": null
                    },
                    {
                        "name": "Conduct Problems",
                        "tScore": 48,
                        "percentile": null
                    },
                    {
                        "name": "Externalizing Problems",
                        "tScore": 47,
                        "percentile": null
                    },
                    {
                        "name": "Anxiety",
                        "tScore": 53,
                        "percentile": null
                    },
                    {
                        "name": "Depression",
                        "tScore": 43,
                        "percentile": null
                    },
                    {
                        "name": "Somatization",
                        "tScore": 44,
                        "percentile": null
                    },
                    {
                        "name": "Internalizing Problems",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Attention Problems",
                        "tScore": 65,
                        "percentile": null
                    },
                    {
                        "name": "Learning Problems",
                        "tScore": 71,
                        "percentile": null
                    },
                    {
                        "name": "School Problems",
                        "tScore": 69,
                        "percentile": null
                    },
                    {
                        "name": "Atypicality",
                        "tScore": 47,
                        "percentile": null
                    },
                    {
                        "name": "Withdrawal",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Behavioral Symptoms Index",
                        "tScore": 49,
                        "percentile": null
                    },
                    {
                        "name": "Adaptability",
                        "tScore": 42,
                        "percentile": null
                    },
                    {
                        "name": "Social Skills",
                        "tScore": 53,
                        "percentile": null
                    },
                    {
                        "name": "Leadership",
                        "tScore": 46,
                        "percentile": null
                    },
                    {
                        "name": "Study Skills",
                        "tScore": 35,
                        "percentile": null
                    },
                    {
                        "name": "Functional Communication",
                        "tScore": 35,
                        "percentile": null
                    },
                    {
                        "name": "Adaptive Skills",
                        "tScore": 41,
                        "percentile": null
                    }
                ],
                "selectedForm": null
            }
        ]
    }
]

export const isTokenExpired = (token) => {
  // Split the token (header.payload.signature)
  const tokenParts = token.split('.');

  if (tokenParts.length !== 3) {
    return true; // Invalid token format
  }

  // Decode the payload (it's base64 encoded)
  const payload = JSON.parse(atob(tokenParts[1]));

  // Check for the expiration time (exp) in the payload
  if (payload.exp) {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    return payload.exp < currentTime; // Token is expired if exp < current time
  }

  return true; // If no exp claim, consider the token expired
}

export const getLocalDateStringFromUTC = (utcDate) => {
  if (!utcDate){
    return '';
  }
  const localDate = new Date(utcDate);
  const formattedDate = localDate.toLocaleDateString();
  return formattedDate
}
