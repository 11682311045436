import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';

import rootReducer from './reducers'

//import AppendixBuilder from './components/ReportGenerator/AppendixBuilder';
import AdminAssessmentUploader from './components/Admin/AdminAssessmentUploader';
import AdminHome from './components/Admin/AdminHome';
import AdminRatingScaleUploader from './components/Admin/AdminRatingScaleUploader';
import Login from './components/Login';
import PasswordReset from './components/PasswordReset/PasswordReset';
import PasswordResetLandingPage from './components/PasswordReset/PasswordResetLandingPage';
import FirstLoginPasswordResetLandingPage from './components/PasswordReset/FirstLoginPasswordResetLandingPage';
import ReportDashboard from './components/ReportDashboard/ReportDashboard';
import ReportGenerator from './components/ReportGenerator/ReportGenerator';
import ReportUploader from './components/ReportUploader/ReportUploader';
import HeaderWrapper from './shared/HeaderWrapper';



let store = createStore(rootReducer, applyMiddleware(thunk));
store.dispatch({
  type: 'AUTHENTICATION_CACHED',
  data: !!localStorage.getItem('token'),
});


export default class App extends Component {
  render() {
    return (
      <Provider store={store}>
          <Router>
            <Routes>
              <Route exact path='/' element={<Login/>}/>
              <Route path='/adminassessmentuploader' element={
                <HeaderWrapper>
                  <AdminAssessmentUploader/>
                </HeaderWrapper>
              }/>
              <Route path='/adminhome' element={
                <HeaderWrapper>
                  <AdminHome/>
                </HeaderWrapper>
              }/>
              <Route path='/adminratingscaleuploader' element={
                <HeaderWrapper>
                  <AdminRatingScaleUploader/>
                </HeaderWrapper>
              }/>
              <Route path='/generatereport/:eval_id?' element={
                <HeaderWrapper>
                  <ReportGenerator/>
                </HeaderWrapper>
              }/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/uploadreport' element={
                <HeaderWrapper>
                  <ReportUploader/>
                </HeaderWrapper>
              }/>
              <Route path="/reset-password/:uidb64/:token" element={<PasswordReset/>} />
              <Route path="/set_password" element={<FirstLoginPasswordResetLandingPage/>} />
              <Route path="/reset_password" element={<PasswordResetLandingPage/>} />
              <Route exact path='/reportdashboard' element={
                <HeaderWrapper>
                  <ReportDashboard/>
                </HeaderWrapper>
              }/>
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          </Router>
      </Provider>
    )
  }
}
