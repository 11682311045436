import React, { Component } from "react";

import withNavigateHook from '../../shared/withNavigateHook';

import Layout from '../../shared/Layout'

import { Button, Box, FormControl, FormLabel, FormControlLabel, MenuItem, Paper, Radio, RadioGroup, Typography, TextField } from '@mui/material';


const styles = {
  boxStyle:{
    flexDirection: 'row',
    marginBottom: '1%',
    textAlign: 'center',
    width: '50%',
    minWidth: '300px',
    '& .MuiTextField-root': { width: '50ch' }
  },
  formFields: {
    width: '40%',
    margin: "10px .5%",
    height: '50px',
  },
  formControlStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  formLabelStyle: {
    marginRight: '20px',
    fontSize: '16px',
    color: '#6e6e6e',
    width: '150px', // Fixed width for labels to align them
    textAlign: 'right', // Right align the labels
  },
  menuItem: {
   textAlign: 'left',
  },
};


const AGE_OPTIONS = Array.from({ length: 100 }, (_, i) => i + 1);

class StartReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      evalType: null,
      age: null,
      sex: null,
      pronouns: ""
    };
    this.setFieldValue = this.setFieldValue.bind(this);
  }

  componentDidMount() {
    const { reportData } = this.props

    if (reportData){
      this.setState({ age: reportData.age, sex: reportData.sex, evalType: reportData.evalType, pronouns: reportData.pronouns })
    }
  }

  componentDidUpdate(prevProps) {
    const { reportData } = this.props

    if (prevProps.reportData !== reportData){
      this.setState({ age: reportData.age, sex: reportData.sex, evalType: reportData.evalType, pronouns: reportData.pronouns })
    }
  }

  setFieldValue(field, newValue) {
    this.setState({[field]: newValue});
  }

  validData = () => {
    if (this.state.evalType &&  this.state.sex && this.state.age){
      return true
    }
    return false
  }

  handleClick = (e) => {
    e.preventDefault()
    const { evalType, age, sex, pronouns } = this.state
    const { setDemographicData, setCurrentStep, reportInProgress } = this.props;

    const evalInputs = {'evalType': evalType, 'age': age, 'sex': sex}
    if (pronouns) {
      evalInputs['pronouns'] = pronouns
    }

    setDemographicData(evalInputs)
    if (!reportInProgress){
      setCurrentStep({ currentStep: 'add-assessment-results' })
    }
  }

  render() {
    const { reportInProgress } = this.props;

    const rootStyle =  {
      width:'100%',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: reportInProgress ? 'flex-start' : 'center',
      marginLeft: reportInProgress ? '400px' : '0', // Adjust margin if the sidebar is visible
      transition: 'all 0.3s ease',
    }

    const headerTextStyle = {
      textAlign: reportInProgress ? 'left': 'center',
      width:'100%',
      marginTop: '20px',
      marginBottom: '24px',
      fontSize: '24px',
      fontWeight: 500,
      letterSpacing: '0.02em'
    }

    return(
      <Layout>
        <div style={rootStyle}>
          {reportInProgress ?
            <Typography sx={headerTextStyle}>
              Evaluation Type & Client Info
            </Typography>:
            <Typography sx={headerTextStyle}>
              Start a new report
            </Typography>
          }
            <Box
              component="form"
              style={styles.boxStyle}
              noValidate
              autoComplete="off"
            >
              <Paper elevation={3}  style={{ padding: '30px', marginTop: '30px' }}>
                {/* Select an evaluation type*/}
                <div style={{ marginBottom: '5px' }}>
                  <FormControl key={"evaluation-type"} component="fieldset" style={styles.formControlStyle}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <FormLabel component="legend" required style={styles.formLabelStyle}>
                        Evaluation Type
                      </FormLabel>
                      <RadioGroup
                        row
                        value={this.state.evalType || ""}
                        onChange={(e) => this.setFieldValue('evalType', e.target.value)}
                        aria-label="evaluation-type"
                        name="evaluation-type"
                      >
                        <FormControlLabel
                          value="Neuropsychological"
                          control={<Radio style={{ color: '#6858a5' }} />}
                          label="Neuropsychological"
                          style={{ marginRight: '40px' }}
                        />
                        <FormControlLabel
                          value="Psychoeducational"
                          control={<Radio style={{ color: '#4a4a4a' }} />}
                          label="Psychoeducational"
                        />
                      </RadioGroup>
                    </div>
                  </FormControl>
                </div>
                <div style={{marginBottom: '15px'}}>
                  <FormControl key={'Client Sex'} variant="outlined" required style={styles.formControlStyle}>
                    <FormLabel component="legend" style={styles.formLabelStyle}>
                      Client Sex
                    </FormLabel>
                    <TextField
                      id="sex-select"
                      value={this.state.sex || ""}
                      select
                      onChange={(e) => this.setFieldValue('sex', e.target.value)}
                      label="Sex"
                      sx={styles.menuItem}
                      style={styles.formFields} // Add formFields style here
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                  </FormControl>
                  <FormControl key={'Client Age'} variant="outlined" required style={styles.formControlStyle}>
                    <FormLabel component="legend" style={styles.formLabelStyle}>
                      Client Age
                    </FormLabel>
                    <TextField
                      id="age-select"
                      value={this.state.age || ""}
                      select
                      label="Age"
                      onChange={(e) => this.setFieldValue('age', e.target.value)}
                      sx={styles.menuItem}
                      style={styles.formFields} // Add formFields style here
                    >
                      {AGE_OPTIONS.map(age => (
                        <MenuItem key={age} value={age}>{age}</MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                  <FormControl key={'Client Pronouns'} variant="outlined" style={styles.formControlStyle}>
                    <FormLabel component="legend" style={styles.formLabelStyle}>
                      Client Pronouns
                    </FormLabel>
                    <TextField
                      key={"Client Pronouns"}
                      required={false}
                      id={"Client Pronouns"}
                      type={"text"}
                      label="Pronouns (optional)"
                      value={this.state.pronouns || ""}
                      onChange={(e) => this.setFieldValue('pronouns', e.target.value)}
                      style={styles.formFields} // Add formFields style here
                    />
                  </FormControl>
                </div>
                <Button
                  disabled={!this.validData()}
                  variant="contained"
                  sx={{backgroundColor: '#EF4651'}}
                  size="large"
                  onClick={(e) => this.handleClick(e)}
                >
                  <Typography>
                    {reportInProgress === true ?
                      `Save`:
                      `Start`
                    }
                  </Typography>
                </Button>
              </Paper>
            </Box>
        </div>
      </Layout>
    );
  }
};


export default withNavigateHook(StartReport);
