import React, { Component } from "react";
import { connect } from "react-redux";

import withNavigateHook from '../../shared/withNavigateHook';

import Layout from '../../shared/Layout'

import { PHI_FIELDS, REQUIRED_PHI_FIELDS, DATE_PHI_FIELDS, REPORT_SECTION_TITLES, EVALUATION_NOTE_SECTIONS, CONFLICTING_REPORT_SECTIONS } from '../../shared/constants'

import { numberToOrdinal } from '../../shared/constants'

import { deidentifySensitiveInfo } from '../../shared/deidentifier'

import { FormHelperText } from '@mui/material';

import { Typography, TextField, Checkbox, List, ListItem, ListItemText, ListItemSecondaryAction, Button, Box, IconButton, Paper, Divider, Dialog, DialogContent, DialogContentText, DialogActions  } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

import Tooltip from '@mui/material/Tooltip';

import Add from "@mui/icons-material/Add";

const styles = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'left',
    marginBottom: '100px', // Add margin to account for the sticky footer
    marginLeft: '350px',
    // overflow:'hidden'
  },
  main: {
    width: 'auto',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  headerText: {
    textAlign: 'left',
    marginTop: '20px',
    marginBottom: '24px',
    fontSize: '24px',
    fontWeight: 500,
    letterSpacing: '0.02em'
  },
  instructionDiv: {
    textAlign: 'left',
    width:'100%'
  },
  instructionHeaderText: {
    marginTop: '20px',
    marginBottom: '15px',
    fontSize: '20px',
    fontWeight: 400
  },
  instructionText: {
    marginBottom: '24px',
    fontSize: '16px',
    fontWeight: 400
  },
  instructionBulletText: {
    fontSize: '14px',
    fontWeight: 400
  },
  formHeaderText: {
    fontSize: '20px',
    fontWeight: 300
  },
  sectionHeaderText: {
    fontSize: '20px',
    fontWeight: 400,
    marginBottom:'5px'
  },
  sectionSubheaderText: {
    fontSize: 14,
    fontWeight: 400,
    marginTop: '5px',
    marginBottom: '10px',
    fontStyle: 'italic'
  },
  phiForms:{
    marginBottom: '1%',
    textAlign: 'left',
    width: '70%',
    overflow: 'hidden',
    padding:'5px',
    '& .MuiTextField-root': { width: '50ch' }
  },
  phiFields: {
    width: '49%',
    margin: "5px .5%",
    minWidth: '250px',
  },
  logoutButton: {
    marginTop: '20px',
    marginLeft: '75%',
    background:'#E74C3C',
  },
  startButton: {
    background:'#E74C3C',
    height: '40px'
  },
  startButtonDiv: {
    marginBottom: '18px',
  },
  reportTextFieldDiv: {
    marginBottom: '24px',
    // marginLeft: '10%',
    // marginRight: '10%',
  },
  reviewReminderText: {
    marginTop: '24px',
    marginBottom: '24px',
    fontSize: '14px',
    fontStyle: 'italic'
  },
  toolTipText: {
    fontSize: '14px',
    fontStyle: 'italic'
  },
  redirectButton: {
    marginTop: 8,
    background:'#E74C3C',
    textTransform:'none'
  },
  menuItem: {
   textAlign: 'left',
 },
 listContainer: {
    display: 'flex',
    justifyContent: 'center', // Center the lists and divider horizontally
    alignItems: 'stretch', // Stretch the items to fill the container
    width: '100%'
  },
  list: {
    flex: 1, // Allow the lists to grow and take equal space
    padding: '10px',
  },
  listItem: {
    marginBottom: '0px',
  },
  listItemText: {
    fontSize: 16,
  },
  submitButton: {
    marginTop: '20px',
  },
  paper: {
    padding: '10px',
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch', // Stretch the items to fill the container
    overflow: 'hidden'
  },
  draggableItem: {
    padding: '7px',
    marginBottom: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    borderRadius: '4px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    // Ensure the draggable item style is compatible with react-beautiful-dnd
  },
  divider: {
    alignSelf: 'stretch', // Make the divider span the full height of its container
    margin: '0 20px',
  },
  stickyFooter: {
    position: 'fixed',
    bottom: 0,
    width: 'calc(100% - 410px)', //adjust for margin + padding
    backgroundColor: 'white',
    padding: '10px 20px',
    zIndex: 1000, // Ensure the footer is above other content
    display: 'flex',
    justifyContent: 'left', // Center the buttons horizontally
  },
  buttonContainer: {
    margin: '0 10px', // Spacing between buttons
  },
};

const PRIORITY_SECTIONS = [
  "Tests Administered",
  "Reason For Referral",
  "Background",
  "Behavioral Observations",
  "Summary Of Test Results",
  "Recommendations"
];

class EvaluationNotesUploader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phiFields: {
        // Objects to store values of other text fields
        'Client Personal Information': {},
        'Family Information': {},
        'Schools Attended': {},
        'Teachers Referenced': {},
        'Support Practitioners (Dr.s, psychologists, tutors, etc.)': {},

        // State to store values of dynamically generated text fields
        'Additional School': {'count': 5, 'initialCount':5, 'schoolNames':[]},
        'Additional Family Member': {'count': 0, 'firstNameValues':[], 'lastNameValues':[]},
        'Additional Teacher': {'count': 0, 'firstNameValues':[], 'lastNameValues':[]},
        'Additional Practitioner': {'count': 0, 'firstNameValues':[], 'lastNameValues':[]},
      },
      noteSectionData: {},
      recentlyDeidentified: false,
      notesUpdated: false,
      phiFieldsUpdated: false,
      validationErrors: [],
      onFormReview: null,
      loading: true,
      selectedSections: [],
      orderedSections: [],
      displayTextFields: false,
      displayReportSectionSelector: false
    };

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleSubmitSections = this.handleSubmitSections.bind(this);
    this.handleDisplayReportSectionSelector = this.handleDisplayReportSectionSelector.bind(this)
    this.scrollToReportSections = React.createRef();
  }

  componentDidMount() {
    const { sectionsAndNotes, evalId } = this.props
    const formattedSectionsAndNotes = {}
    let orderedSections = []

    sectionsAndNotes.forEach(section => {
      const sectionTitle = section['reportSectionTitle']
      const notes = section['notes']
      formattedSectionsAndNotes[sectionTitle] = notes
      orderedSections.push(sectionTitle)
    })

    // If no report section data has been stored, set orderedsections to the prioritized sections so they are preselected
    if (orderedSections.length === 0){
        orderedSections = PRIORITY_SECTIONS
    }

    const savedFormData = localStorage.getItem('phiFields-'+evalId.toString());
    this.setState({
      phiFields: savedFormData ? {...JSON.parse(savedFormData)}: this.state.phiFields,
      noteSectionData:formattedSectionsAndNotes,
      selectedSections: orderedSections,
      orderedSections: orderedSections,
      displayTextFields: sectionsAndNotes.length>0 ? true: false,
      displayReportSectionSelector: sectionsAndNotes.length>0 ? true: false,
      loading: false
    });

    let scrollPosition = localStorage.getItem('noteScrollPosition')
    if (scrollPosition){
      setTimeout(function () {
          window.scrollTo(0, scrollPosition);
        }, 1
      );
      localStorage.removeItem('noteScrollPosition')
    }
  }

  saveNotesToLocalStorage = () => {
    localStorage.setItem('phiFields-'+this.props.evalId.toString(), JSON.stringify(this.state.phiFields));
  }

  handleScrollToReportSections = () => {
    // Scroll to the report section selection div when button is clicked
    const offset = 64
    const elementPosition = this.scrollToReportSections.current.getBoundingClientRect().top + window.scrollY;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth'
    });
  };

  closeAddPhiFieldsDialog  = () => {
    this.setState({openAddPhiFieldsDialog: false})
  }

  getPhiFieldType = (fieldName) => {
    if (DATE_PHI_FIELDS.includes(fieldName)){
      return "date"
    }
    return "text"
  };

  handleNameAddFieldButtonClick = (phiType) => {
    this.setState((prevState) => {
      var updatedPhi = {...prevState.phiFields[phiType]}
      updatedPhi.count += 1
      updatedPhi.firstNameValues = [...updatedPhi.firstNameValues, '']
      updatedPhi.lastNameValues = [...updatedPhi.lastNameValues, '']
      return {
        phiFields: {...prevState.phiFields, [phiType]: updatedPhi}
      };
    }, this.saveNotesToLocalStorage);
  };

  handleSchoolAddFieldButtonClick = (phiType) => {
    this.setState((prevState) => {
      var updatedPhi = {...prevState.phiFields[phiType]}
      updatedPhi.count += 1
      return {
        phiFields: {...prevState.phiFields, [phiType]: updatedPhi}
      };
    }, this.saveNotesToLocalStorage);
  };

  generateNameFields = (fieldType) => {
    let listOfFields = [];
    for (let i = 1; i <= this.state.phiFields[fieldType].count; i++) {
      listOfFields.push(this.generateNameInputFields(fieldType, i));
    }
    return listOfFields;
  };

  generateNameInputFields = (additionalFieldType, additionalFieldNum) => {

    const nameValueKeys = {'First Name': 'firstNameValues', 'Last Name': 'lastNameValues'}
    return (
      ['First Name', 'Last Name'].map(name =>
          <TextField
            variant="outlined"
            label={`${additionalFieldType} ${additionalFieldNum} ${name}`}
            value={this.state.phiFields[additionalFieldType][nameValueKeys[name]][additionalFieldNum-1] || ""}
            onChange={(e) => this.handleDynamicFieldChange(additionalFieldType, nameValueKeys[name], additionalFieldNum-1, e.target.value)}
            style={styles.phiFields}
          />
      )
    );
  };

  generateSchoolFields = () => {
    const listOfFields = []
    const additionalSchoolCount = this.state.phiFields['Additional School'].count
    const initialSchoolCount = this.state.phiFields['Additional School'].initialCount
    if (additionalSchoolCount > initialSchoolCount){
      for (let i = initialSchoolCount+1; i <= additionalSchoolCount; i++) {
        listOfFields.push(this.generateSchoolInputFields(i));
      }
    }
    return listOfFields;
  };

  generateSchoolInputFields = (fieldNum) => {
    const fieldOrdinal = numberToOrdinal(fieldNum)
    return (
          <TextField
            variant="outlined"
            label={`${fieldOrdinal} School` }
            value={this.state.phiFields['Additional School']['schoolNames'][fieldNum-(this.state.phiFields['Additional School'].initialCount+1)] || ""}
            onChange={(e) => this.handleDynamicSchoolFieldChange('Additional School', 'schoolNames', fieldNum-(this.state.phiFields['Additional School'].initialCount+1), e.target.value)}
            style={styles.phiFields}
          />
      )
  };

  handleDynamicSchoolFieldChange = (fieldType, fieldName, index, value) => {
    // Function to handle change in dynamically generated text fields
    this.setState(prevState => {
      const updatedValues = prevState.phiFields[fieldType]; // Copy existing values

      updatedValues[fieldName][index] = value; // Update value at specified index
      return { phiFields: {...prevState.phiFields, [fieldType]: updatedValues }, phiFieldsUpdated: true}; // Update state
    }, this.saveNotesToLocalStorage);
  };

  handleDynamicFieldChange = (additionalFieldType, valueType, index, value) => {
    // Function to handle change in dynamically generated text fields
    this.setState(prevState => {
      const updatedValues = prevState.phiFields[additionalFieldType]; // Copy existing values

      updatedValues[valueType][index] = value; // Update value at specified index
      return { phiFields:{...prevState.phiFields, [additionalFieldType]: updatedValues}, phiFieldsUpdated: true }; // Update state
    }, this.saveNotesToLocalStorage);
  };

  handleTextFieldChange = (fieldType, fieldName, value) => {
    // Function to handle change in other text fields
    this.setState(prevState => {
      const updatedValues = prevState.phiFields[fieldType];
      updatedValues[fieldName] = value
      return { phiFields: {...prevState.phiFields, [fieldType]: updatedValues}, phiFieldsUpdated: true };
    }, this.saveNotesToLocalStorage);
  };

  handleNoteSectionTextFieldChange = (noteSection, value) => {
    this.setState(
      (prevState) => ({
        noteSectionData: {
          ...prevState.noteSectionData,
          [noteSection]: value,
        },
      }),
      () => {
        // callback runs after the state is updated
        const notesUpdated = this.notesUpdated();
        this.setState({ notesUpdated: notesUpdated, recentlyDeidentified: !notesUpdated });
        this.props.setNoteSaveState(notesUpdated ? false : true);
      }
    );
  };

  conflictingSectionAdded(section) {
    return this.state.selectedSections.some(selectedSection => CONFLICTING_REPORT_SECTIONS.hasOwnProperty(selectedSection) && CONFLICTING_REPORT_SECTIONS[selectedSection].includes(section))
  }

  handleCheckboxChange(section) {
    if (!this.requiredFieldsEntered()){
      this.setState({openAddPhiFieldsDialog: true})
    }
    else {
      this.setState(prevState => {
        const selectedSections = prevState.selectedSections.includes(section)
          ? prevState.selectedSections.filter(s => s !== section)
          : [...prevState.selectedSections, section];

        const orderedSections = prevState.selectedSections.includes(section)
          ? prevState.orderedSections.filter(s => s !== section)
          : [...prevState.orderedSections, section];

        // remove associated noteSectionData if section in removed, else add
        let noteSectionData = {...prevState.noteSectionData}
        if (Object.keys(prevState.noteSectionData).includes(section)) {
          delete noteSectionData[section]
        }
        else {
          noteSectionData[section] = null
        }

        return { selectedSections, orderedSections, noteSectionData };
      });
    }
  }

  handleDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(this.state.orderedSections);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    this.setState({ orderedSections: items }, this.saveNotesToLocalStorage);
  }

  // create sections
  handleSubmitSections() {
    const { updateNotes } = this.props
    const { orderedSections } = this.state

    const formattedSections = orderedSections.map(s => { return {reportSectionTitle: s, notes: null} })
    localStorage.setItem('noteScrollPosition', JSON.stringify(window.scrollY))
    updateNotes({sectionsAndNotes: formattedSections})
    this.setState({ displayTextFields: true })
  }

  handleDisplayReportSectionSelector = () => {
    this.setState({displayReportSectionSelector: true}, this.handleScrollToReportSections)
  }

  handleDeidentifyData = (e) => {
    e.preventDefault()
    const deIdentifiedData = {}
    const noteFields = Object.keys(this.state.noteSectionData)

    noteFields.forEach((field) => {
      if (this.state.noteSectionData[field]){
        deIdentifiedData[field] = deidentifySensitiveInfo(this.state, field, 'noteSectionData')
      }
      else {
        deIdentifiedData[field] = null
      }

    })

    const fieldType = 'noteSectionData'
    // Function to handle change in other text fields
    this.setState(prevState => {
      return {[fieldType]: deIdentifiedData}
    });
    this.setState({recentlyDeidentified:true, phiFieldsUpdated:false})
  };

  formatNotesForSave = (notesData) => {
    const reportSectionTitles = this.state.orderedSections
    const formattedNotes = []
    reportSectionTitles.forEach(title => {
      formattedNotes.push({reportSectionTitle: title, notes: notesData[title] || null})
    })
    return formattedNotes
  }

  handleSave = () => {
    const { updateNotes, setNoteSaveState } = this.props
    const { noteSectionData } = this.state

    const formattedNotes = this.formatNotesForSave(noteSectionData)
    localStorage.setItem('noteScrollPosition', JSON.stringify(window.scrollY))

    updateNotes({sectionsAndNotes: formattedNotes})
    setNoteSaveState(true)
    this.setState({ loading: true })
  };

  requiredFieldsEntered = () => {
    return (
      this.state.phiFields['Client Personal Information']['Client First Name'] &&
      (this.state.phiFields['Client Personal Information']['Client First Name'] !== '') &&
      this.state.phiFields['Client Personal Information']['Client Last Name'] &&
      (this.state.phiFields['Client Personal Information']['Client Last Name'] !== '')
    )
  }

  reportSectionsUpdated = () => {
    return JSON.stringify(this.props.sectionsAndNotes.map(s => s.reportSectionTitle)) !== JSON.stringify(this.state.orderedSections)
  }

  notesUpdated = () => {
    const { noteSectionData } = this.state
    const { sectionsAndNotes } = this.props

    return (
      Object.keys(noteSectionData).some(section => {
        const existingSection = sectionsAndNotes.find(s => s.reportSectionTitle === section)
        const newNotes = noteSectionData[section]

        // if it's a new section and there are any notes, return true
        if (!existingSection && noteSectionData[section]){
          return true
        }
        // if the section exists and there are changes to notes, return true
        if (existingSection && existingSection.notes !== newNotes){
          return true
        }

        return false
      })
    )
  }

  notesAdded = () => {
    const { noteSectionData } = this.state
    return (
      Object.keys(noteSectionData).some(section => {
        if (noteSectionData[section]){
          return true
        }
        return false
      })
    )
  }

  reportSectionsAdded = () => {
    const { sectionsAndNotes } = this.props
    return (
      sectionsAndNotes.length > 0
    )
  }

  enableDeidentify = () => {
    return (
      this.requiredFieldsEntered() &&
      this.notesAdded() &&
      ((!this.state.recentlyDeidentified && this.state.notesUpdated) || this.state.phiFieldsUpdated)
    )
  }

  enableSave = () => {
    return (
      this.requiredFieldsEntered() &&
      this.reportSectionsAdded() &&
      (
        (this.state.notesUpdated && this.state.recentlyDeidentified) ||
        (this.reportSectionsUpdated() && (!this.state.notesUpdated || this.state.recentlyDeidentified))
      )
    )
  }


  renderAddPhiFieldsDialog = () => {
    const { openAddPhiFieldsDialog } = this.state
    return (
      <Dialog
       open={openAddPhiFieldsDialog}
       onClose={this.closeAddPhiFieldsDialog}
       aria-labelledby="add-phi-fields-dialog"
      >
       <DialogContent>
         <DialogContentText>
          <Typography sx={{fontSize: '15px', fontWeight: 500, color: 'black'}}>
            {this.props.regenerating ? `To add new report sections and notes, please re-enter the Client First Name, Client Last Name and any other relevant fields in the identifying information form above.`:
              `To add report sections and notes, please enter Client First Name, Client Last Name and any other relevant fields in the identifying information form above.`
            }
          </Typography>
         </DialogContentText>
       </DialogContent>
       <DialogActions>
         <div style={{width: '100%', textAlign: 'center'}}>
           <Button onClick={this.closeAddPhiFieldsDialog}>
             Close
           </Button>
          </div>
       </DialogActions>
      </Dialog>
    )
  }


  render() {
    if (this.state.loading===true){
      return (
        <Layout>
          <div style={{ padding: '40px', textAlign:'left'}}>
            <Typography sx={styles.subHeaderText}>
              Loading...
            </Typography>
          </div>
        </Layout>
      )
    }

    const OTHER_SECTIONS = REPORT_SECTION_TITLES.filter(
      (section) => !PRIORITY_SECTIONS.includes(section)
    );

    return(
      <Layout>
        <div style={styles.root}>
          <div> {/* wrap to prevent resizing issues*/}
            <div style={{marginLeft:'50px'}}>
              {this.renderAddPhiFieldsDialog()}
              <div style={styles.instructionText}>
                <Typography sx={styles.headerText}>
                  Select Report Sections and Add Evaluation Notes
                </Typography>
                <Typography sx={styles.instructionText}>
                  1. Add your client’s identifying information into the form below, where relevant, and click Continue to Report Sections.
                    <Typography style={styles.instructionBulletText}>
                      <li style={{marginLeft: '20px'}}>You are required to include: Client First Name, Client Last Name.</li>
                      <li style={{marginLeft: '20px'}}>Any 'identifiers' provided in the form, will be removed whereever they appear in your notes.</li>
                    </Typography>
                  2. Select and order your report sections, then click Save & Continue to Notes.<br/>
                  3. Add your notes to the note boxes for each report section, where applicable.<br/>
                  4. Click De-Identify, review and then click Save to save your deidentified notes.<br/>
                </Typography>
              </div>
              <Box
                component="form"
                sx={styles.phiForms}
                noValidate
                autoComplete="off"
              >
                <div style={{textAlign:'center', outline: '.5px solid black', padding:'25px', marginBottom: '20px'}}>
                  <div>
                    <Typography sx={styles.formHeaderText}>
                      Client Personal Information
                    </Typography>
                    <div style={{ marginBottom: '4px' }}>
                      {PHI_FIELDS['Client Personal Information'].map((phiField) => (
                          DATE_PHI_FIELDS.includes(phiField) ? (
                            <TextField
                              key={phiField}
                              required={REQUIRED_PHI_FIELDS.includes(phiField)}
                              id={phiField}
                              label={phiField}
                              type={this.getPhiFieldType(phiField)}
                              InputLabelProps={{ shrink: DATE_PHI_FIELDS.includes(phiField) }}
                              value={this.state.phiFields['Client Personal Information'][phiField] || ""}
                              onChange={(e) => this.handleTextFieldChange('Client Personal Information', phiField, e.target.value)}
                              style={styles.phiFields}
                              size="small"
                            />
                          ) :
                          (
                            <TextField
                              key={phiField}
                              required={REQUIRED_PHI_FIELDS.includes(phiField)}
                              id={phiField}
                              label={phiField}
                              type={this.getPhiFieldType(phiField)}
                              value={this.state.phiFields['Client Personal Information'][phiField] || ""}
                              onChange={(e) => this.handleTextFieldChange('Client Personal Information', phiField, e.target.value)}
                              style={styles.phiFields}
                              size="small"
                              helperText = {REQUIRED_PHI_FIELDS.includes(phiField) ? <FormHelperText style={{margin:0, color:'red'}}>(required*)</FormHelperText>: null}
                            />
                          )
                        ))
                      }
                      <TextField disabled style={{ width: '50%', visibility: 'hidden', height: '1px' }} />
                    </div>
                  </div>

                  <div style={{marginBottom: '20px'}}>
                    <Typography sx={styles.formHeaderText}>
                      Family Information
                    </Typography>
                    <div style={{marginBottom:'5px'}}>
                      {PHI_FIELDS['Family Information'].map(phiField => (
                        <TextField
                          required={REQUIRED_PHI_FIELDS.includes(phiField)}
                          id={phiField}
                          label={phiField}
                          type = {this.getPhiFieldType(phiField)}
                          value={this.state.phiFields['Family Information'][phiField] || ""}
                          onChange={(e) => this.handleTextFieldChange('Family Information', phiField, e.target.value)}
                          style={styles.phiFields}
                          size="small"
                        />
                      ))}
                    {this.generateNameFields('Additional Family Member')}
                    </div>
                      {
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{height:'30px', backgroundColor: '#EF4651'}}
                          onClick={() => this.handleNameAddFieldButtonClick('Additional Family Member')}
                        >
                          <Add />
                        </Button>
                      }
                  </div>

                  <div style={{marginBottom: '20px'}}>
                    <Typography sx={styles.formHeaderText}>
                      Schools Attended
                    </Typography>
                    <div style={{marginBottom:'5px'}}>
                      {PHI_FIELDS['Schools Attended'].map(phiField => (
                        <TextField
                          id={phiField}
                          label={phiField}
                          value={this.state.phiFields['Schools Attended'][phiField] || ""}
                          onChange={(e) => this.handleTextFieldChange('Schools Attended', phiField, e.target.value)}
                          style={styles.phiFields}
                          size="small"
                        />

                      ))}
                    {this.generateSchoolFields('Additional School')}
                    </div>
                      {
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{height:'30px', backgroundColor: '#EF4651'}}
                          onClick={() => this.handleSchoolAddFieldButtonClick('Additional School')}
                        >
                          <Add />
                        </Button>
                      }
                  </div>

                  <div style={{marginBottom: '20px'}}>
                    <Typography sx={styles.formHeaderText}>
                      Teachers Referenced
                    </Typography>
                    <div style={{marginBottom:'5px'}}>
                      {PHI_FIELDS['Teachers Referenced'].map(phiField => (
                        <TextField
                          id={phiField}
                          label={phiField}
                          value={this.state.phiFields['Teachers Referenced'][phiField] || ""}
                          onChange={(e) => this.handleTextFieldChange('Teachers Referenced', phiField, e.target.value)}
                          style={styles.phiFields}
                          size="small"
                        />

                      ))}
                    {this.generateNameFields('Additional Teacher')}
                    </div>
                    {
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{height:'30px', backgroundColor: '#EF4651'}}
                        onClick={() => this.handleNameAddFieldButtonClick('Additional Teacher')}
                      >
                        <Add />
                      </Button>
                    }
                  </div>

                  <div>
                    <Typography sx={styles.formHeaderText}>
                      Support Practitioners (Dr.s, psychologists, tutors, etc.)
                    </Typography>
                    <div style={{marginBottom:'5px'}}>
                      {PHI_FIELDS['Support Practitioners (Dr.s, psychologists, tutors, etc.)'].map(phiField => (
                        <TextField
                          id={phiField}
                          label={phiField}
                          value={this.state.phiFields['Support Practitioners (Dr.s, psychologists, tutors, etc.)'][phiField] || ""}
                          onChange={(e) => this.handleTextFieldChange('Support Practitioners (Dr.s, psychologists, tutors, etc.)', phiField, e.target.value)}
                          style={styles.phiFields}
                          size="small"
                        />

                      ))}
                    {this.generateNameFields('Additional Practitioner')}
                    </div>
                      {
                        <Button
                          variant="contained"
                          color="primary"
                          sx={{height:'30px', backgroundColor: '#EF4651'}}
                          onClick={() => this.handleNameAddFieldButtonClick('Additional Practitioner')}
                        >
                          <Add />
                        </Button>
                      }
                  </div>
                </div>

                {this.state.displayReportSectionSelector &&
                  <div ref={this.scrollToReportSections} style={{ marginBottom: '20px' }}>
                    <Typography sx={styles.sectionHeaderText}>Select and Order Report Sections</Typography>
                    <Paper style={styles.paper}>
                      <div style={styles.listContainer}>
                        <div style={styles.list}>
                          <Typography style={styles.formHeaderText}>Available Sections</Typography>
                          <List>
                            {PRIORITY_SECTIONS.map((section) => (
                              <ListItem dense key={section} style={styles.listItem}>
                                <ListItemText dense primary={<li style={styles.listItemText}>{section}</li>} />
                                <ListItemSecondaryAction>
                                  <Checkbox
                                    checked={this.state.selectedSections.includes(section)}
                                    onChange={() => this.handleCheckboxChange(section)}
                                    disabled={this.conflictingSectionAdded(section) ||
                                      (this.props.regenerating &&
                                        this.props.sectionsAndNotes.filter(s => s.hasBeenGenerated === true).map(s => s.reportSectionTitle).includes(section))}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}

                            <Divider style={{ margin: "10px 0" }} />

                            {OTHER_SECTIONS.map((section) => (
                              <ListItem dense key={section} style={styles.listItem}>
                                <ListItemText dense primary={<li style={styles.listItemText}>{section}</li>} />
                                <ListItemSecondaryAction>
                                  <Checkbox
                                    checked={this.state.selectedSections.includes(section)}
                                    onChange={() => this.handleCheckboxChange(section)}
                                    disabled={this.conflictingSectionAdded(section) ||
                                      (this.props.regenerating &&
                                        this.props.sectionsAndNotes.filter(s => s.hasBeenGenerated === true).map(s => s.reportSectionTitle).includes(section))}
                                  />
                                </ListItemSecondaryAction>
                              </ListItem>
                            ))}
                          </List>
                        </div>

                        <Divider orientation="vertical" flexItem style={styles.divider} />

                        <div style={styles.list}>
                          <Typography sx={styles.formHeaderText}>Selected Sections</Typography>
                          <Typography sx={{fontSize:'14px', color:'#EF4651', marginTop:'5px', marginBottom:'5px'}}>Drag and drop sections into the order you would like them to appear in your report</Typography>
                          <DragDropContext onDragEnd={this.handleDragEnd}>
                            <Droppable droppableId="sections">
                              {(provided) => (
                                <List {...provided.droppableProps} ref={provided.innerRef}>
                                  {this.state.orderedSections.map((section, index) => (
                                    <Draggable key={section} draggableId={section} index={index}>
                                      {(provided) => (
                                        <ListItem
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...styles.draggableItem,
                                            ...provided.draggableProps.style,
                                          }}
                                        >
                                          <Typography>{section}</Typography>
                                          <IconButton {...provided.dragHandleProps}>
                                            <DragIndicatorIcon />
                                          </IconButton>
                                        </ListItem>
                                      )}
                                    </Draggable>
                                  ))}
                                  {provided.placeholder}
                                </List>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      </div>
                    </Paper>
                  </div>
                }
                {this.state.displayTextFields && (
                  <div>
                    <Typography sx={styles.sectionHeaderText}>Add Report Section Notes</Typography>
                      For each report section, add any notes you would like reflected in this section of your report, or leave the box blank.
                    <Typography style={{marginTop:'10px', fontSize:'14px', fontWeight:500}}>Important:</Typography>
                    <ul style={{marginTop: 0}}>
                      <li>
                        <Typography sx={{fontSize:'14px', fontWeight: 500, color:'#EF4651'}}>In order to save your notes, you must enter values for Client First Name and Client Last Name in the de-identification fields above. </Typography>
                      </li>
                      <li>
                        <Typography sx={{fontSize:'14px', fontWeight: 500, color:'#EF4651'}}>Note boxes will not appear for Tests Administered, Reason For Referral, Summary and Recommendations when selected. These sections will be automatically generated using your test scores and the notes you provide for other sections.</Typography>
                      </li>
                    </ul>
                    {this.state.orderedSections.filter(section => EVALUATION_NOTE_SECTIONS.includes(section)).map(section => (
                      <div key={section} style={styles.reportTextFieldDiv}>
                        <Typography sx={styles.formHeaderText}>
                          {section}
                        </Typography>
                        <TextField
                          required
                          style={{ width: '100%', marginTop:'5px'}}
                          minRows={5}
                          multiline={true}
                          label="Add your notes"
                          id={section}
                          value={this.state.noteSectionData[section] || ""}
                          onChange={(e) => this.handleNoteSectionTextFieldChange(section, e.target.value)}
                        />
                      </div>
                    ))}
                  </div>
                )}
                {this.state.recentlyDeidentified === true && this.state.notesUpdated &&
                  <div style={styles.reviewReminderText}>
                      Please review your de-identified evaluation notes above. Make sure there is no identifying information present in your notes before continuing. To remove any remaining PHI, you may edit your notes directly, or click the 'Edit PHI Fields' button (above the note sections) to make changes to the identifiable information fields and rerun deidentification. Once all identifying information has been removed, click Save.
                  </div>
                }
              </Box>
            </div>
            <div style={styles.stickyFooter}>
              {this.props.sectionsAndNotes.length === 0 ? (
                <div style={{width:'70%', display: 'flex', justifyContent: 'center'}}>
                  <div style={styles.buttonContainer}>
                    <Tooltip
                      title={
                        !this.requiredFieldsEntered() ? <Typography>Please fill in all required* Client Personal Information fields</Typography>:
                        this.state.selectedSections.length === 0 && <Typography>Please select your report sections to continue</Typography>
                      }
                    >
                      <span>
                        {this.state.displayReportSectionSelector ?
                          <Button variant="contained" type="submit" sx={styles.startButton} onClick={this.handleSubmitSections} disabled={this.state.selectedSections.length === 0 || !this.requiredFieldsEntered()}>
                            Save & Continue to Notes
                          </Button>:
                          <Button variant="contained" type="submit" sx={styles.startButton} onClick={this.handleDisplayReportSectionSelector} disabled={!this.requiredFieldsEntered()}>
                            Continue to Report Sections
                          </Button>
                        }
                      </span>
                    </Tooltip>
                  </div>
                </div>
                ): (
                <div style={{width:'70%', display: 'flex', justifyContent: 'center'}}>
                  <div style={styles.buttonContainer}>
                    <Tooltip
                      title={
                        !this.requiredFieldsEntered() ?
                          (<Typography>Please fill in all required* Client Personal Information fields</Typography>):
                          (<Typography>Notes are deidentified</Typography>)
                      }
                      disableHoverListener={this.enableDeidentify()}
                    >
                      <span>
                        <Button
                          type="submit"
                          size="small"
                          variant="contained"
                          sx={styles.startButton}
                          disabled={!this.enableDeidentify()}
                          onClick={(e) => this.handleDeidentifyData(e)}
                        >
                          <Typography>
                            De-Identify
                          </Typography>
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                  <div style={styles.buttonContainer}>
                    <Tooltip
                      title={
                        this.notesUpdated() && !this.state.recentlyDeidentified ? <Typography>Notes must be deidentified before saving</Typography>: <Typography>Notes are up to date</Typography>
                      }
                      disableHoverListener={this.enableSave()}
                    >
                      <span>
                        <Button
                          size="small"
                          variant="contained"
                          sx={styles.startButton}
                          disabled={!this.enableSave()}
                          onClick={this.handleSave}
                        >
                          <Typography>
                            Save
                          </Typography>
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Layout>
    );
  }
};

const mapStateToProps = state => {
  return {
  };
}

const mapDispatchToProps = dispatch => {
  return {
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNavigateHook(EvaluationNotesUploader));
